import React, { useState, useEffect } from 'react';
import { Container, Button, Pagination, PaginationItem, Row, Col, Progress, Table, Input } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";
import { PageCard } from "../components/card/PageCard";
import { CustomPageCard } from "../components/card/CustomPageCard";
import { PaginationLink } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { getUsers } from "../hooks/userInstance"
import { usePermission } from 'hooks/permissionInstance';
import { PermissionEnum } from "models/enums/permissionEnum";

function getStatusStyle(hasAccess, locked) {
    if (hasAccess && !locked) {
        return { color: '#00FF00' }; // Bright green
    }
    else if (!hasAccess && !locked) {
        return { color: '#DAA520' }; // Gold
    }
    else if (!hasAccess && locked) {
        return { color: 'red' };
    }
    else if (hasAccess && locked) {
        return { color: 'red' };
    }
}

export const UserManagement = ({ finishAddingPipeline, existingPipelines }) => {

    function getStatus(hasAccess, locked) {
        if (hasAccess && !locked) {
            return "Active"; // Active
        }
        else if (!hasAccess && !locked) {
            return "Inactive"; // Inactive
        }
        else if (!hasAccess && locked) {
            return "Pending"; // Pending
        }
    }

    const [users, setUsers] = useState([]);
    const [pendingUsers, setPendingUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [loading, setLoading] = useState(true); // State for loading
    const permissionInstance = usePermission();
    const [currentUserId, setCurrentUserId] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const storedUser = await getUsers();

            if (storedUser) {
                setCurrentUserId(sessionStorage.getItem("userId"));
                setUsers(storedUser);
                const pendingUsers = storedUser.filter(user => user.userDetails.hasAccess === false);
                const otherUsers = storedUser.filter(user => user.userDetails.hasAccess === true);
                setPendingUsers(pendingUsers);
                setUsers(otherUsers);
            } else {
                console.log("no roles found in sessionStorage");
            }
            setLoading(false); // Set loading to false after data retrieval
        };
        fetchData(); // Call async function
    }, []);

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const totalPages = Math.ceil(users.length / usersPerPage);
    const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
    const [sortedColumn, setSortedColumn] = useState('');

    const searchInput = (
        <Input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
        />
    );

    let navigate = useNavigate();

    const navigateToEditUser = (user) => {
        navigate('/edit-user', { state: { user } });
    };

    const currentUsers = users
        .filter(user =>
        (user.userDetails.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.userDetails.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        .slice(indexOfFirstUser, indexOfLastUser);

    const sortUsers = (column) => {
        const sortedUsers = [...users].sort((a, b) => {
            let itemA = a[column];
            let itemB = b[column];

            // For dates, you may need to create a Date object for proper comparison
            if (column === 'dateAdded') {
                itemA = itemA.split('-').reverse().join('-');
                itemB = itemB.split('-').reverse().join('-');
                itemA = new Date(itemA);
                itemB = new Date(itemB);
            } else {
                itemA = typeof itemA === 'string' ? itemA.toUpperCase() : itemA;
                itemB = typeof itemB === 'string' ? itemB.toUpperCase() : itemB;
            }

            if (itemA < itemB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (itemA > itemB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setUsers(sortedUsers);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        setSortedColumn(column);
    };

    const cardBody = (
        <div style={{ minHeight: '60vh', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Table>
                <thead>
                    <tr>
                        <th style={{ width: '35%', cursor: 'pointer' }} onClick={() => sortUsers('name')}>
                            Name {sortedColumn === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th style={{ width: '23%', cursor: 'pointer' }} onClick={() => sortUsers('email')}>
                            Email {sortedColumn === 'email' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th style={{ width: '17%', cursor: 'pointer' }} onClick={() => sortUsers('dateAdded')}>
                            Modified Date {sortedColumn === 'dateAdded' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th style={{ width: '13%', cursor: 'pointer' }} onClick={() => sortUsers('status')}>
                            Status {sortedColumn === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th style={{ width: '17%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {currentUsers.map((user, index) => (
                        <tr key={index}>
                            <td>
                                {currentUserId === user.id ? (
                                    <span>
                                        {user.userDetails.firstName} <span style={{ color: 'orange' }}>(You)</span>
                                    </span>
                                ) : (
                                    user.userDetails.firstName
                                )}
                            </td>
                            <td>{user.userDetails.email}</td>
                            <td>{user.userDetails.modifiedDate}</td>
                            <td style={getStatusStyle(user.userDetails.hasAccess, user.userDetails.locked)}>
                                {user.userDetails.locked ? 'Inactive' :
                                    user.userDetails.hasAccess ? 'Active' : 'Pending'}
                            </td>
                            {permissionInstance.permissions.includes(PermissionEnum.UPDATE_USER) ? ( //TODO check permission
                                <td>
                                    <Button onClick={() => navigateToEditUser(user)}>Edit User</Button>
                                </td>
                            ) : (<td></td>)}
                        </tr>
                    ))}
                </tbody>

            </Table >
            <div className="d-flex justify-content-between align-items-center ml-5 mr-5">
                <div>Showing {((currentPage - 1) * usersPerPage) + 1} to {((currentPage - 1) * usersPerPage) + currentUsers.length} of {users.length} entries</div>
                <div>
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                        </PaginationItem>
                        {[...Array(totalPages)].map((_, index) => (
                            <PaginationItem key={index} active={index + 1 === currentPage}>
                                <PaginationLink onClick={() => setCurrentPage(index + 1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                        </PaginationItem>
                    </Pagination>
                </div>
            </div>
        </div>
    );

    const handleButtonClick = () => {
        navigate('/pending-users', { state: { pendingUsers } });
    };

    if (loading) {
        return <div>Loading...</div>; // Or display a nice spinner/component for loading
    }

    return (
        pendingUsers.length > 0 ? (
            <CustomPageCard cardName="User Overview" cardBody={cardBody}>
                {permissionInstance.permissions.includes(PermissionEnum.APPROVE_USER) && ( //TODO check permission
                    <Button className='mx-3 position-relative' onClick={handleButtonClick}>
                        <FontAwesomeIcon icon={faHourglassHalf} />
                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger text-white">
                            {pendingUsers.length}
                        </span>
                    </Button>
                )}
                {searchInput}
            </CustomPageCard>
        ) : (
            <PageCard cardName={"User Overview"} cardBody={cardBody} />
        )
    );
}

export default UserManagement;
