import { UniversalInput } from 'components/Forms/UniversalInput';
import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

function FromToDateSelector({fromDate, toDate, onFromDateChange, onToDateChange, allowCustom = true, showLabel = true, noMargin = false}) {
    const [timeRange, setTimeRange] = useState("7 Days");

    const availableOptions = useMemo(() => {
        const opts = ["7 Days", "1 Month", "6 Months"];
        if (allowCustom) {
            opts.push("Custom")
        }

        return opts;
    }, [allowCustom])

    useEffect(() => {
        switch (timeRange) {
            case "7 Days":
                onFromDateChange(dayjs().subtract('6', 'day'))
                break;
            case "1 Month":
                onFromDateChange(dayjs().subtract('1', 'month'))
                break;
            case "6 Months":
                onFromDateChange(dayjs().subtract('6', 'month'))
                break;
            default:
                onFromDateChange(dayjs())
        }
        onToDateChange(dayjs())
    }, [timeRange])

    return (
        <>
            <UniversalInput id="timeRange" label={showLabel ? 'Interval' : undefined} setValue={setTimeRange} value={timeRange} placeholder="" fieldRequired={true} dropdown={true} options={availableOptions} noMargin={noMargin} />
            {timeRange === 'Custom' && allowCustom && <div className='mb-3'>
                <p className='m-0'>From:</p>
                <DatePicker value={fromDate} onChange={(newValue) => onFromDateChange(newValue)} />
                <p className='mt-2 mb-0'>To: </p>
                <DatePicker value={toDate} onChange={(newValue) => onToDateChange(newValue)} />
            </div>
            }
        </>
    );
}

export default FromToDateSelector;
