import React, { useState } from 'react';
import { Container, Row, Button, Col } from 'reactstrap';
import { UniversalInput } from '../components/Forms/UniversalInput'

export const AddToken = ({finishAddingToken}) => {

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        return result;
    }

    const [tokenName, setTokenName] = useState("");
    const [tokenCode, setTokenCode] = useState(generateRandomString(36));
    const [expirationDate, setExperationDate] = useState();

    const handleFinish = () => {
        if(expirationDate !== null){
            if(new Date(expirationDate) <= new Date()){
                alert("Expiration date has to be in future");
                return;
            }
        }  
        if(tokenName.length < 3){
            alert("Fill in a valid name");
            return;
        }
        finishAddingToken(tokenName, tokenCode, expirationDate);
    };

    const handleCopyToClipboard = () => {

        if (tokenCode) {
            const tempInput = document.createElement("input");
            tempInput.value = tokenCode;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
        }
    };

    return (
        <Container>
            <Row className="mt-4">
                <Col>
                    <h2>Api token</h2>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <p>Please copy this token and store it somewhere safely. After this, you won't be able to see this token anymore.</p>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <UniversalInput id="name" label="Name" setValue={setTokenName} value={tokenName} />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <UniversalInput id="token" label="Token" setValue={setTokenCode} value={tokenCode} readonly={true} />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <UniversalInput id="Experation date" label="Experation date" setValue={setExperationDate} value={expirationDate} type="date" />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Button color="primary" className="ml-2" onClick={() => handleCopyToClipboard()}>Copy to Clipboard</Button>
                    <Button color="primary" className="ml-2" onClick={() => handleFinish()}>Save</Button>
                </Col>
            </Row>
        </Container>
    );
}