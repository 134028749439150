import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { defaultHeaders } from "components/auth/GetToken";
import axios from "components/axios/axios";
import { useOrganization } from "hooks/organizationInstance";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Alert, Button } from "reactstrap";

export default function ConversationDialog({ open, conversationId, onClose }) {
    const organizationInstance = useOrganization();
    const [messages, setMessages] = useState([])
    const [errMsg, setErrMsg] = useState('');

    const handleClose = () => {
        setErrMsg('');
        setMessages([]);
        onClose();
    }

    async function fetchConversationMessages() {
        const headers = await defaultHeaders();

        if (!organizationInstance.organization) {
            return;
        }

        axios.get(`/message/conversation?conversationId=${conversationId}&organisationId=${organizationInstance.organization.id}`, headers)
            .then((res) => {
                setMessages(res.data)
            })
            .catch((error) => {
                console.error(error)
                setErrMsg('Failed to fetch conversation messages');
            })
    }

    useEffect(() => {
        if (!open) return;

        fetchConversationMessages();
    }, [open])

    return (
        <Dialog open={open} maxWidth='lg' fullWidth onClose={handleClose}>
            <DialogTitle className="d-flex justify-content-between align-items-center"><span>Conversation <pre className="d-inline" style={{ cursor: 'pointer' }} onClick={() => {
                navigator.clipboard.writeText(conversationId)
                toast.success('Copied to clipboard')
            }}>{conversationId}</pre></span><Button onClick={onClose} size="sm" color="danger" className="px-3">X</Button></DialogTitle>
            <DialogContent>
                {
                    errMsg && <Alert color='danger'>{errMsg}</Alert>
                }
                {
                    !organizationInstance.organization &&
                    <Alert color='warning'><span class="alert-icon"><i class="fa-solid fa-triangle-exclamation"></i></span>Please select an organization</Alert>
                }
                <div className="card border" style={{ height: '600px', overflowY: 'scroll' }}>
                    <div className="card-body">{
                        messages.map((message) => (
                            <ChatMessage message={message} />
                        ))
                    }</div>
                </div>
            </DialogContent>

        </Dialog>
    )
}

function ChatMessage({ message }) {
    return (
        <>
            <div className="d-flex justify-content-end">
                <div>
                    <Alert color="primary" style={{ textAlign: 'end' }} className="border">
                        {message.question}
                    </Alert>
                </div>
            </div>
            <div className="d-flex">
                <div className="mr-5">
                    <small className="text-muted">{message.createdAt}</small>
                    <Alert color="secondary" className="border">
                        {message.result}
                    </Alert>
                </div>
            </div>
        </>

    )
}