import { msalInstance } from "../authConfig";

import { useNavigate } from "react-router-dom";
import { useRole } from "../hooks/roleInstance";
import { useOrganization } from "hooks/organizationInstance";
import { useAuth } from "hooks/authInstance";
import { usePermission } from "hooks/permissionInstance";

function Logout() {
    const navigate = useNavigate();
    const roleInstance = useRole();
    const organizationInstance = useOrganization();
    const permissionInstance = usePermission();
    const authInstance = useAuth();

    const logout_ = async () => {
        const msal = await msalInstance();
        (await msal).logoutPopup();
        roleInstance.GetRole();
        permissionInstance.setPermissions(undefined);
        sessionStorage.clear();

        if (organizationInstance.organization) {
            organizationInstance.setOrganizations([]);
            organizationInstance.setOrganization(null);
        }

        authInstance.setIsAuthenticated(false);
        navigate("/signin");

    }
    logout_();
}

export default Logout;