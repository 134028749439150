import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";
import { PageCard } from "../components/card/PageCard";
import { PaginationLink } from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { PageCardTabs } from '../components/card/PageCardTabs';
import { ActivateUser, DeactivateUser, RejectUser, UpdateUser, getUser } from './../hooks/userInstance';
import { useAuth } from 'hooks/authInstance';

export const Profile = () => {
    let navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    const [user, setUser] = useState({});
    const [userFirst, setUserFirst] = useState('');
    const [userLast, setUserLast] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userActivation, setUserActivation] = useState(false);
    const [initialUserActivation, setInitialUserActivation] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userId = sessionStorage.getItem("userId");
                if (!userId) {
                    console.error("No userId found in sessionStorage");
                    return;
                }

                const usercontext = await getUser(userId);
                if (!usercontext || !usercontext.user || !usercontext.user.userDetails) {
                    console.error("Invalid usercontext structure", usercontext);
                    return;
                }

                const userDetails = usercontext.user.userDetails;
                setUser(userDetails);
                setUserFirst(userDetails.firstName);
                setUserLast(userDetails.lastName);
                setUserEmail(userDetails.email);
                setUserPhone(userDetails.phoneNumber);
                const isActive = getStatus(userDetails.hasAccess, userDetails.locked) === "Active";
                setUserActivation(isActive);
                setInitialUserActivation(isActive);
            } catch (error) {
                console.error("Error fetching user data", error);
            }
        };
        if (isAuthenticated) {
            fetchUserData();
        }
    }, [isAuthenticated]);



    function getStatus(hasAccess, locked) {
        if (hasAccess && !locked) {
            return "Active"; // Brighter green
        }
        else if (!hasAccess && !locked) {
            return "Pending"; // Darker yellow
        }
        else if (!hasAccess && locked) {
            return "InActive";
        }
    }

    const handleChange = (e) => {
        const { name, checked, type } = e.target;

        if (name === 'firstname') {
            setUserFirst(e.target.value);
        }
        else if (name === 'lastname') {
            setUserLast(e.target.value);
        }
        else if (name === 'email') {
            setUserEmail(e.target.value);
        } else if (name === 'phoneNumber') {
            setUserPhone(e.target.value);
        } else if (name === 'status') {
            setUserActivation(checked); // Update the state based on the checkbox
        }
    };

    const saveUser = async () => {

        await UpdateUser(sessionStorage.getItem("userId"), userFirst, userLast, userEmail, userPhone);

        //extra call only if the checkbox value is different
        if (userActivation !== initialUserActivation) {
            if (userActivation) {

                await ActivateUser(user.userId);

            } else {
                await DeactivateUser(user.userId);
            }
        }
        navigate('/user');
    };

    const General = (
        <Form>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="name">First Name</Label>
                        <Input type="text" name="firstname" id="firstname" placeholder="First Name" value={userFirst}
                            onChange={handleChange} // Add the handleChange function
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="name">Last Name</Label>
                        <Input type="text" name="lastname" id="lastname" placeholder="Last Name" value={userLast}
                            onChange={handleChange} // Add the handleChange function
                        />
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup>
                <Label for="email">User contact email</Label>
                <Input type="email" name="email" id="email" placeholder="Email" value={userEmail}
                    onChange={handleChange} // Add the handleChange function
                />
            </FormGroup>
            <FormGroup>
                <Label for="phoneNumber">Phone Number</Label>
                <Input type="text" name="phoneNumber" id="phoneNumber" placeholder="Phone Number" value={userPhone}
                    onChange={handleChange} // Add the handleChange function
                />
            </FormGroup>

            <FormGroup className='mt-3 mb-5' check>
                <div>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="status"
                            id="activateUser"
                            checked={userActivation} // Check if the status is 'Active'
                            onChange={handleChange} // Add the handleChange function
                        />{' '}
                        Activate User
                    </Label>

                </div>
                <div>
                    <small className="text-muted">If you leave this unchecked, the user will be deactivated.</small>
                </div>
            </FormGroup>


            <FormGroup>
                <Row>
                    <Col md={6} className="d-flex justify-content-start">
                        <Button color="success" onClick={() => saveUser(user)}>Save</Button>
                    </Col>
                </Row>
            </FormGroup>

        </Form>
    );


    return (
        <PageCard cardName="Profile" cardBody={General} />


    );
}

export default Profile;
