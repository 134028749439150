import React, { useEffect, useState } from "react";

import SignIn from "pages/SignIn";
import { Route, Routes, Navigate} from "react-router-dom";
import './app.css'
import { ToastContainer, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import ProtectedLayout from "layouts/ProtectedLayout";
import Logout from "pages/Logout";
import { useAuth } from "hooks/authInstance";


export const App = () => {
    const { isAuthenticated } = useAuth();

    return (
        <>
            <Routes>
                <Route path="/signin" element={isAuthenticated ? <Navigate to="/" replace /> : <SignIn />} />
                {isAuthenticated ? (
                    <Route path="/*" element={<ProtectedLayout />} />
                ) : (
                    <Route path="*" element={<Navigate to="/signin" replace />} />
                )}
                <Route path="/logout" element={<Logout />} />
            </Routes>
            <ToastContainer position="top-center" hideProgressBar transition={Zoom} />
        </>
    );
};
