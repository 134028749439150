import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";
import { PageCard } from "../components/card/PageCard";
import { PaginationLink } from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { PageCardTabs } from '../components/card/PageCardTabs';
import { getRoles } from "../hooks/roleInstance"
import { ActivateUser, DeactivateUser, RejectUser, UpdateUser, getUsers } from "../hooks/userInstance"
import { PermissionEnum } from "models/enums/permissionEnum";
import { usePermission } from 'hooks/permissionInstance';

function getStatusStyle(hasAccess, locked) {
    if (hasAccess && !locked) {
        return { color: '#00FF00' }; // Brighter green
    }
    else if (!hasAccess && !locked) {
        return { color: '#DAA520' }; // Darker yellow
    }
    else if (!hasAccess && locked) {
        return { color: 'red' };
    }
}

function getStatus(hasAccess, locked) {
    if (hasAccess && !locked) {
        return "Active"; // Brighter green
    }
    else if (!hasAccess && !locked) {
        return "Pending"; // Darker yellow
    }
    else if (!hasAccess && locked) {
        return "InActive";
    }
}

export const EditUser = () => {
    const location = useLocation();
    const [user, setUser] = useState(location.state.user);
    const [selectedRoles, setSelectedRoles] = useState(user ? user.roles : []);
    const [userFirst, setUserFirst] = useState(user.userDetails.firstName);
    const [userLast, setUserLast] = useState(user.userDetails.lastName);
    const [userEmail, setUserEmail] = useState(user.userDetails.email);
    const [userPhone, setUserPhone] = useState(user.userDetails.phoneNumber);
    const [userActivation, setUserActivation] = useState(getStatus(user.userDetails.hasAccess, user.userDetails.locked) === "Active");
    const [initialUserActivation, setInitialUserActivation] = useState(getStatus(user.userDetails.hasAccess, user.userDetails.locked) === "Active");
    let navigate = useNavigate();
    const permissionInstance = usePermission();

    let sessionsMainUser = sessionStorage.getItem("userId");
    const saveUser = async () => {
        await UpdateUser(user.id, userFirst, userLast, userEmail, userPhone, selectedRoles.map(role => role.role.id));

        // Extra call only if the checkbox value is different
        if (userActivation !== initialUserActivation) {
            if (userActivation) {
                await ActivateUser(user.id);
            } else {
                await DeactivateUser(user.id);
            }
        }
        navigate('/user');
    };

    const deleteUser = async () => {
        if (window.confirm("Are you sure you want to delete this account?")) {
            await RejectUser(user.id);
            navigate('/user');
        }
    };

    const handleChange = (e) => {
        const { name, checked, type } = e.target;

        if (name === 'fname') {
            setUserFirst(e.target.value);
        }
        else if (name === 'lname') {
            setUserLast(e.target.value);
        } else if (name === 'email') {
            setUserEmail(e.target.value);
        } else if (name === 'phoneNumber') {
            setUserPhone(e.target.value);
        } else if (name === 'status') {
            setUserActivation(checked); // Update the state based on the checkbox
        }
    };

    const General = (
        <Form>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="name">First Name</Label>
                        <Input
                            type="text"
                            name="fname"
                            id="name"
                            placeholder="First Name"
                            value={userFirst}
                            onChange={handleChange} // Add the handleChange function
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="name">Last Name</Label>
                        <Input
                            type="text"
                            name="lname"
                            id="name"
                            placeholder="Last Name"
                            value={userLast}
                            onChange={handleChange} // Add the handleChange function
                        />
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup>
                <Label for="email">User Contact Email</Label>
                <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={userEmail}
                    onChange={handleChange} // Add the handleChange function
                />
            </FormGroup>

            <FormGroup>
                <Label for="phoneNumber">Phone Number</Label>
                <Input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    value={userPhone}
                    onChange={handleChange} // Add the handleChange function
                />
            </FormGroup>

            {permissionInstance.permissions.includes(PermissionEnum.ACTIVATE_USER) && ( // TODO check permission
                <FormGroup className='mt-3 mb-5' check>
                    <div>
                        <Label check>
                            <Input
                                type="checkbox"
                                name="status"
                                id="activateUser"
                                checked={userActivation} // Check if the status is 'Active'
                                onChange={handleChange} // Add the handleChange function
                            />{' '}
                            Activate User
                        </Label>
                    </div>
                    <div>
                        <small className="text-muted">If you leave this unchecked, the user will be deactivated.</small>
                    </div>
                </FormGroup>
            )}
            <FormGroup>
                <Row>
                    <Col md={6} className="d-flex justify-content-start">
                        <Button color="success" onClick={() => saveUser(user)}>Save</Button>
                    </Col>
                    {permissionInstance.permissions.includes(PermissionEnum.DELETE_USER) && ( // TODO check permission
                        <Col md={6} className="d-flex justify-content-end">
                            {sessionsMainUser !== user.id && <Button color="danger" onClick={deleteUser}>Delete Account</Button>}
                        </Col>
                    )}
                </Row>
            </FormGroup>
        </Form>
    );

    const RolesTab = () => {
        const [modal, setModal] = useState(false);
        const toggleModal = () => setModal(!modal);

        const [roles, setRoles] = useState([]);

        useEffect(() => {
            const fetchData = async () => {
                const storedRoles = await getRoles();
                if (storedRoles) {
                    setRoles(storedRoles);
                } else {
                    console.error('Roles not found in database.');
                }
            };
            fetchData(); // Call asynchronous function
        }, []);
        const availableRoles = roles.filter(role =>
            !selectedRoles.some(selectedRole => selectedRole.role.id === role.id)
        );

        const addPermissionToSelected = (role) => {
            setSelectedRoles([...selectedRoles, { role }]);
        };

        const removePermissionFromSelected = (roleToRemove) => {
            setSelectedRoles(selectedRoles.filter(item => item.role.id !== roleToRemove.id));
        };
        // Function to add permission to the selected permissions

        return (
            <Form>
                <FormGroup>
                    <div className="d-flex justify-content-between align-items-center">
                        <Label className="mb-0">Current Roles</Label>
                        <Button className='mb-3' color="primary" onClick={toggleModal}>
                            Add
                        </Button>
                    </div>
                    <ListGroup className="flex-grow-1">
                        {selectedRoles.map((currRole, index) => (
                            <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                                {currRole.role.name}
                                <Button color="danger" size="sm" onClick={() => removePermissionFromSelected(currRole.role)}>
                                    <i className="fas fa-trash"></i>
                                </Button>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </FormGroup>

                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Available Roles</ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            {availableRoles.map((currRole, index) => (
                                <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                                    {currRole.name}
                                    <Button color="success" size="sm" onClick={() => {
                                        addPermissionToSelected(currRole);
                                    }}>
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </Form>
        );
    };

    const tabItems = [
        { tabId: '1', title: 'General', content: General },
        { tabId: '2', title: 'Roles', content: RolesTab() }
    ];

    return (
        <PageCardTabs cardName="Edit User" tabItems={tabItems} />
    );
}

export default EditUser;
