import React, { createContext, useContext, useEffect, useState } from 'react';
import { getTokenClaims, defaultHeaders } from "../components/auth/GetToken"

import axios from "../components/axios/axios"

const RoleContext = createContext();
let token = await getTokenClaims();
let headers = await defaultHeaders();

const FetchData = async () => {
  token = await getTokenClaims();
  headers = await defaultHeaders();
}

export const getUsers = async () => {
  if (token === null) {
    await FetchData();
  }

  try {

    const response = await axios.get("/user/all", headers);

    return response.data; // Geeft de response data terug
  } catch (error) {
    console.error(error);
    return null; // Of een meer specifieke foutafhandeling
  }
};

export const getUser = async (id) => {
  if (token === null) {
    await FetchData();
  }

  if (token !== null) {
    try {
      const response = await axios.get("/user/" + id, headers);
      return response.data; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const UpdateUser = async (id, first, last, email, phone, roleIds) => {
  const token = await getTokenClaims(); // Zorg ervoor dat je token ophaalt

  if (token !== null) {
    try {
      const headers = await defaultHeaders(); // Zorg ervoor dat je headers ophaalt

      let body = {
        "id": id,
        "firstName": first, // Gebruik direct de variabele
        "lastName": last, // Gebruik direct de variabele
        "email": email,
        "phoneNumber": phone
      };

      if (roleIds !== null) {
        body.roles = roleIds; // Voeg de roles toe als roleIds niet null of leeg is
      }


      const response = await axios.patch("/user/update", body, headers);
      return response; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const InviteUsers = async (first, last, email, phone, organisationId) => {
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {

      let body = {
        "firstName": first, // Gebruik direct de variabele zonder string template
        "lastName": last, // Gebruik direct de variabele zonder string template
        "email": email,
        "phoneNumber": phone,
        "organisationId": organisationId
      };

      const response = await axios.post("/user/invite", body, headers);
      return response; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const DeactivateUser = async (id) => {
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {
      const ids = Array.isArray(id) ? id : [id];
      let body = {
        "userIds": ids,
      };
      const response = await axios.post("/user/deactivate", body, headers);
      return response; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const ActivateUser = async (id) => {
  if (token === null) {
    await FetchData();
  }

  if (token !== null) {
    try {
      let body = {
        "userId": id,
      };
      const response = await axios.post("/user/activate", body, headers);
      return response; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const ApproveUser = async (ids) => {
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {
      let body = {
        "userIds": ids,
      };

      const response = await axios.post("/user/approve", body, headers);
      return response; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const RejectUser = async (id) => {
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {
      const ids = Array.isArray(id) ? id : [id];

      let body = {
        "userIds": ids,
      };

      const response = await axios.post("/user/reject", body, headers);
      return response; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

