import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";
import { PageCard } from "../components/card/PageCard";
import { PaginationLink } from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { PageCardTabs } from '../components/card/PageCardTabs';
import { getRoles } from "../hooks/roleInstance"
import { ActivateUser, DeactivateUser, InviteUsers, RejectUser, UpdateUser, getUsers } from "../hooks/userInstance"
import { CustomPageCard } from 'components/card/CustomPageCard';
import { getTokenClaims, defaultHeaders } from "../components/auth/GetToken";
import { useOrganization } from 'hooks/organizationInstance';

function getStatusStyle(hasAccess, locked) {
    if (hasAccess && !locked) {
        return { color: '#00FF00' }; // Brighter green
    }
    else if (!hasAccess && !locked) {
        return { color: '#DAA520' }; // Darker yellow
    }
    else if (!hasAccess && locked) {
        return { color: 'red' };
    }
}

function getStatus(hasAccess, locked) {
    if (hasAccess && !locked) {
        return "Active"; // Brighter green
    }
    else if (!hasAccess && !locked) {
        return "Pending"; // Darker yellow
    }
    else if (!hasAccess && locked) {
        return "InActive";
    }
}

export const InviteUser = () => {
    const [user, setUser] = useState(null);
    const [selectedRoles, setSelectedRoles] = useState([]);

    // Use optional chaining or check for null before trying to access `userDetails`
    const [userFirst, setUserFirst] = useState(user?.userDetails?.firstName || '');
    const [userLast, setUserLast] = useState(user?.userDetails?.firstName || '');
    const [userEmail, setUserEmail] = useState(user?.userDetails?.email || '');
    const [userPhone, setUserPhone] = useState(user?.userDetails?.phoneNumber || '');
    const organisationId = useOrganization().organization.id;

    let navigate = useNavigate();

    // Function for adding a role
    const saveUser = async () => {
        await InviteUsers(userFirst, userLast, userEmail, userPhone, organisationId);
        // Extra call only if the checkbox value is different
        navigate('/user');
    };

    const handleChange = (e) => {
        const { name, checked, type } = e.target;

        if (name === 'firstname') {
            setUserFirst(e.target.value);
        } else if (name === 'lastname') {
            setUserLast(e.target.value);
        } else if (name === 'email') {
            setUserEmail(e.target.value);
        } else if (name === 'phoneNumber') {
            setUserPhone(e.target.value);
        }
    };

    const General = (
        <Form>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="name">First Name</Label>
                        <Input
                            type="text"
                            name="firstname"
                            id="name"
                            placeholder="First Name"
                            value={userFirst}
                            onChange={handleChange} // Add the handleChange function
                        />
                    </FormGroup>
                </Col>

                <Col md={6}>
                    <FormGroup>
                        <Label for="name">Last Name</Label>
                        <Input
                            type="text"
                            name="lastname"
                            id="name"
                            placeholder="Last Name"
                            value={userLast}
                            onChange={handleChange} // Add the handleChange function
                        />
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup>
                <Label for="email">User Contact Email</Label>
                <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={userEmail}
                    onChange={handleChange} // Add the handleChange function
                />
            </FormGroup>

            <FormGroup>
                <Label for="phoneNumber">Phone Number</Label>
                <Input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    value={userPhone}
                    onChange={handleChange} // Add the handleChange function
                />
            </FormGroup>

            <FormGroup>
                <Row>
                    <Col md={6} className="d-flex justify-content-start">
                        <Button color="success" onClick={() => saveUser(user)}>Invite + </Button>
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    );

    return (
        <CustomPageCard cardName="Invite User" cardBody={General} />
    );
}

export default InviteUser;
