import { Container, Row, Col, Card, CardHeader, CardBody, Button, Input } from 'reactstrap';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';

export const PageCard = ({ cardName, cardBody, button, buttonText, pendingUsers, searchBar }) => {
  let navigate = useNavigate();

  const navigateToEditUser = (pendingUsers) => {
    navigate('/pending-users', { state: { pendingUsers } });
  };

  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs={searchBar ? "8" : "10"}>
                  <h3 className="mb-0">{cardName}</h3>
                </Col>

                <Col xs="4" className="d-flex justify-content-end">

                  {button && (
                    <Button className='m-0 position-relative' onClick={() => navigateToEditUser(pendingUsers)}>
                      <FontAwesomeIcon icon={faHourglassHalf} />
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white">
                        {buttonText} {/* Dit is je rode cijfer, dynamisch aan te passen */}
                      </span>
                    </Button>
                  )}
                  {searchBar && (
                    <Col xs="10">
                      {searchBar}
                    </Col>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {cardBody}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
