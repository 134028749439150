export const PermissionEnum = {
    READ_LLM: 1,
    CREATE_LLM: 2,
    DELETE_LLM: 3,
    READ_DATASOURCE: 4,     
    CREATE_DATASOURCE: 5,     
    DELETE_DATASOURCE: 6,     
    READ_PIPELINE: 7,     
    CREATE_PIPELINE: 8,     
    DELETE_PIPELINE: 9,     
    CREATE_USER: 10,     
    READ_USER: 11,     
    READ_ALL_USER: 12,     
    UPDATE_USER: 13,     
    APPROVE_USER: 14,     
    ACTIVATE_USER: 15,     
    ORGANISATION_USER: 16,     
    INVITE_USER: 17,     
    DELETE_USER: 18,     
    CREATE_ROLE: 19,     
    READ_ROLE: 20,     
    UPDATE_ROLE: 21,     
    DELETE_ROLE: 22,     
    READ_PERMISSIONS: 23,     
    CREATE_TOKEN: 24,     
    READ_TOKEN: 25,     
    DELETE_TOKEN: 26,     
    READ_ORGANISATION: 27,     
    CREATE_ORGANISATION: 28,     
    UPDATE_ORGANISATION: 29, 
    DELETE_ORGANISATION: 30, 
    READ_LOGS: 31, 
    DELETE_LOGS: 32, 
    CREATE_CHATBOT: 33, 
    READ_REFERENCED_DOCUMENTS: 34, 
    READ_API_TRAFFIC: 35,
    READ_DASHBOARD: 36,
    READ_USER_FEEDBACK: 37,
    READ_SETTINGS: 38
};