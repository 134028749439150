import Overview from "pages/Overview.js";
import Datasources from "pages/Datasources";
import Llm from "pages/Llm";
import Logout from "pages/Logout";
import Pipeline from "pages/Pipeline";
import Settings from "pages/Settings";
import Tokens from "pages/Tokens";
import AddOrganization from "pages/AddOrganization";
import UserManagement from "pages/UserManagement";
import RoleManagement from "pages/RolesManagement";
import PermissionsManagement from "pages/PermissionsManagement";
import { Chatbot } from "pages/chatBot";
import EditUser from "pages/EditUser";
import EditRole from "pages/EditRole";
import AddRole from "pages/AddRole";
import Profile from "pages/Profile";
import UserManagement_Pending from "pages/UserManagement_Pending";
import SystemLogs from "pages/SystemLogs/SystemLogs";
import ApiTraffic from "pages/ApiTraffic/ApiTraffic";
import ReferencedDocuments from "pages/ReferencedDocuments/ReferencedDocuments";
import UserFeedback from "pages/UserFeedback/UserFeedback";
import InviteUser from "pages/InviteUser";
import { PermissionEnum } from "models/enums/permissionEnum";

export const GetRoutesWithPermissions = (permissions) => {
    const filterRoutes = (routes) => {
        return routes
            .map(route => {
                const filteredRoute = { ...route };
    
                if (filteredRoute.permission && !permissions.includes(filteredRoute.permission)) {
                    return null;
                }
    
                if (filteredRoute.views) {
                    filteredRoute.views = filterRoutes(filteredRoute.views);
                    
                    if (filteredRoute.views.length === 0) {
                        return null;
                    }
                }
    
                return filteredRoute;
            })
            .filter(Boolean);
    };

    return filterRoutes(routesConfig);
};

const routesConfig = [
    {
        collapse: true,
        key: "dashboard-collapse",
        name: "Dashboards",
        icon: "ni ni-shop text-primary",
        state: "dashboardsCollapse",
        views: [
            {
                key: "dashboard-overview",
                path: "/dashboard",
                name: "Overview",
                miniName: "O",
                component: <Overview />,
                permission: PermissionEnum.READ_DASHBOARD,
            },
            {
                key: "dashboard-api-traffic",
                path: "/api-traffic",
                name: "API Traffic",
                miniName: "A",
                component: <ApiTraffic />,
                permission: PermissionEnum.READ_API_TRAFFIC,
            },
            {
                key: "dashboard-referenced-documents",
                path: "/referenced-documents",
                name: "Referenced Documents",
                miniName: "R",
                component: <ReferencedDocuments />,
                permission: PermissionEnum.READ_REFERENCED_DOCUMENTS,
            },
            {
                key: "dashboard-user-feedback",
                path: "/user-feedback",
                name: "User Feedback",
                miniName: "U",
                component: <UserFeedback />,
                permission: PermissionEnum.READ_USER_FEEDBACK,
            }
        ]
    },
    {
        collapse: true,
        key: "setup-collapse",
        name: "Setup",
        icon: "ni ni-ui-04  text-primary",
        state: "componentsCollapse",
        views: [
            {
                key: "setup-datasources",
                path: "/datasources",
                name: "Data Sources",
                miniName: "D",
                component: <Datasources />,
                permission: PermissionEnum.READ_DATASOURCE,
            },
            {
                key: "setup-llm",
                path: "/llm",
                name: "LLM Model",
                miniName: "L",
                component: <Llm />,
                permission: PermissionEnum.READ_LLM,
            },
            {
                key: "setup-pipeline",
                path: "/pipeline",
                name: "Pipeline",
                miniName: "P",
                component: <Pipeline />,
                permission: PermissionEnum.READ_PIPELINE,
            },
        ],
    },
    {
        collapse: true,
        key: "members-collapse",
        name: "Members",
        icon: "ni ni-single-02 text-primary",
        state: "membersCollapse",
        views: [
            {
                key: "members-user",
                path: "/user",
                name: "Users",
                miniName: "U",
                component: <UserManagement />,
                permission: PermissionEnum.READ_ALL_USER,
            },
            {
                key: "members-role",
                path: "/role",
                name: "Roles",
                miniName: "R",
                component: <RoleManagement />,
                permission: PermissionEnum.READ_ROLE,
            },
            {
                key: "members-permissions",
                path: "/permissions",
                name: "Permissions",
                miniName: "P",
                component: <PermissionsManagement />,
                permission: PermissionEnum.READ_PERMISSIONS,
            },
            {
                key: "members-invite-user",
                path: "/invite-user",
                name: "Invite User",
                miniName: "I",
                component: <InviteUser />,
                permission: PermissionEnum.INVITE_USER,
            },
        ],
    },
    {
        collapse: false,
        name: "Tokens",
        icon: "ni ni-key-25  text-primary",
        state: "tablesCollapse",
        path: "/tokens",
        key: "tokens",
        component: <Tokens />,
        permission: PermissionEnum.READ_TOKEN,
    },
    {
        collapse: false,
        path: "/settings",
        name: "Settings",
        key: "settings",
        icon: "ni ni-settings-gear-65  text-primary",
        component: <Settings />,
        permission: PermissionEnum.READ_SETTINGS,
    },
    {
        collapse: false,
        path: "/edit-user",
        name: "Edit User",
        key: "edit-user",
        hidden: true,
        icon: "ni ni-settings-gear-65  text-primary",
        component: <EditUser />,
        permission: PermissionEnum.UPDATE_USER,
    },
    {
        collapse: false,
        path: "/edit-role",
        name: "Edit Role",
        key: "edit-role",
        hidden: true,
        icon: "ni ni-settings-gear-65  text-primary",
        component: <EditRole />,
        permission: PermissionEnum.UPDATE_ROLE,
    },
    {
        collapse: false,
        path: "/add-role",
        name: "Add Role",
        key: "add-role",
        hidden: true,
        icon: "ni ni-settings-gear-65  text-primary",
        component: <AddRole />,
        permission: PermissionEnum.CREATE_ROLE,
    },
    {
        collapse: false,
        path: "/pending-users",
        name: "Pending Users",
        key: "pending-users",
        hidden: true,
        icon: "ni ni-settings-gear-65  text-primary",
        component: <UserManagement_Pending />,
        permission: PermissionEnum.APPROVE_USER,
    },
    {
        collapse: false,
        path: "/profile",
        name: "Profile",
        key: "profile",
        icon: "ni ni-single-02 text-primary", 
        component: <Profile />,
    },
    {
        collapse: false,
        path: "/logout",
        name: "Logout",
        key: "logout",
        icon: "ni ni-lock-circle-open  text-primary",
        component: <Logout />,
    },
    {
        collapse: false,
        name: "chatbot/demo",
        path: "/chatbot/demo",
        key: "chatbot-demo",
        component: <Chatbot />,
        permission: PermissionEnum.CREATE_CHATBOT,
    },
    {
        collapse: false,
        name: "add-Organization",
        path: "/add-Organization",
        key: "add-organization",
        component: <AddOrganization />,
        permission: PermissionEnum.CREATE_ORGANISATION,
    },
    {
        collapse: false,
        name: "System logs",
        icon: "fa-solid fa-shield-halved text-primary",
        path: "/syslogs",
        key: "system-logs",
        component: <SystemLogs />,
        permission: PermissionEnum.READ_LOGS,
    },
];
