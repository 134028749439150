import { UniversalInput } from 'components/Forms/UniversalInput';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Button } from 'reactstrap';
import FromToDateSelector from 'components/Forms/FromToDateSelector';
import { defaultHeaders } from 'components/auth/GetToken';
import axios from 'components/axios/axios';
import SystemLogsTable from './SystemLogsTable';
import { StatCard } from 'pages/Overview';

function SystemLogs() {
    // Log filters
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1)
    const [logType, setLogType] = useState("Error");
    const [logModule, setLogModule] = useState("");
    const [logMessage, setLogMessage] = useState("");
    const [fromDate, setFromDate] = useState(dayjs().subtract('6', 'day'))
    const [toDate, setToDate] = useState(dayjs())

    const [logs, setLogs] = useState([])
    const [logsCount, setLogsCount] = useState({})

    useEffect(() => {
        fetchLogsCount()
    }, [])

    const fetchLogsCount = async () => {
        const headers = await defaultHeaders();
        try {
            const res = await axios.get(`/logs/count`, headers)
            setLogsCount(res.data);
        } catch (error) {
            console.error(error);
        }
    }
    const fetchLogs = async (p) => {
        const headers = await defaultHeaders();
        try {
            const res = await axios.get(`/logs?startDate=${fromDate.toISOString()}&endDate=${toDate.toISOString()}&module=${logModule}&content=${logMessage}&type=${logType}&currentPage=${p - 1}`, headers)
            fetchLogsCount()
            setLogs(res.data.content)
            setTotalPages(Math.ceil(res.data.totalElements / res.data.pageSize))
        } catch (error) {
            console.error(error);
            toast.error('Failed to fetch logs')
        }
    }

    const handleSubmit = () => {
        fetchLogs(1)
        setPage(1);
    }

    return (
        <div className='row p-3'>
            <div className='col-lg-9'>
                <div className='row p-0 m-0'>
                    <div className='col-lg-4'>
                        <StatCard title={'Info'} value={logsCount.info} icon="fa fa-info-circle" />
                    </div>
                    <div className='col-lg-4'>
                        <StatCard title={'Warning'} value={logsCount.warning} icon="fa fa-exclamation-triangle" color="warning" />
                    </div>
                    <div className='col-lg-4'>
                        <StatCard title={'Error'} value={logsCount.error} icon="fa fa-times-circle" color="danger" />
                    </div>
                </div>
                <div className='row m-0 p-0'>
                    <div className='col-12'>
                        <SystemLogsTable logs={logs} totalPages={totalPages} fetchLogs={fetchLogs} page={page} setPage={setPage} />
                    </div>
                </div>
            </div>
            <div className='col-lg-3'>
                <FromToDateSelector fromDate={fromDate} toDate={toDate} onFromDateChange={setFromDate} onToDateChange={setToDate} />
                <UniversalInput id="logType" label="Logs" setValue={setLogType} value={logType} placeholder="" fieldRequired={true} dropdown={true} options={["Error", "Info", "Warning"]} />
                <UniversalInput id="logModule" label="Module" setValue={setLogModule} value={logModule} placeholder="" fieldRequired={true} />
                <UniversalInput id="logMessage" label="Content" setValue={setLogMessage} value={logMessage} placeholder="" fieldRequired={true} />

                <Button onClick={handleSubmit} color="primary" className='w-100'>Filter</Button>
            </div>

        </div>
    );
}

export default SystemLogs;
