import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

export const OverlapCard = ({ cardBody, clickClose, isOpen }) => {
  return (
    <Modal isOpen={isOpen} toggle={clickClose} size="xl" centered>
      <ModalBody style={{ minHeight: '300px' }} className="position-relative">
        <Button close onClick={clickClose} className="float-right">
          <span aria-hidden>&times;</span>
        </Button>
        {cardBody}
      </ModalBody>
    </Modal>
  );
};
