import React from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

export const CustomPageCard = ({ cardName, cardBody, children }) => {
  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">{cardName}</h3>
                </Col>
                <Col xs="4" className="d-flex justify-content-end">
                  {children} {/* Hier render je de children, wat knoppen of zoekbalken kunnen zijn */}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {cardBody}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
