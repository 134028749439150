import { PageCard } from "components/card/PageCard";
import React from "react";
import { Table, Pagination, PaginationItem, Button, ButtonToggle } from "reactstrap";
import { useOrganization } from "../hooks/organizationInstance";
import { useState } from "react";
import {PaginationLink} from "reactstrap";

function Settings() {
    const organizationInstance = useOrganization();

    const [toggleStates, setToggleStates] = useState(
        organizationInstance.organizations.map(() => false)
    );
    const [llms] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems] = useState([1]);
    const [totalPages] = useState(1);
    const [pageSize] = useState(15);

    const handleToggle = (index) => {
        const newToggleStates = [...toggleStates];
        newToggleStates[index] = !newToggleStates[index];
        setToggleStates(newToggleStates);
    };

    const cardBody = (
        <div style={{minHeight: '60vh', display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <Table>
                <thead>
                    <tr>
                        <th style={{ width: '55%' }}>Company</th>
                        <th style={{ width: '13%' }}>Created Date</th>
                        <th style={{ width: '13%' }}>Active</th>
                        <th style={{ width: '17%' }}>Delete</th>
                    </tr>
                </thead>
                <tbody>
                {organizationInstance.organizations.map((organization, index) => (
                    <tr key={index}>
                        
                        <th style={{ width: '55%' }}>{organization.name}</th>
                        <td style={{ width: '13%' }}>now</td>
                        <td style={{ width: '13%' }}>
                        <ButtonToggle
                            color={toggleStates[index] ? "danger" : "primary"}
                            onClick={() => handleToggle(index)}
                            >
                            {toggleStates[index] ? "OFF" : "ON"}
                        </ButtonToggle>
                        </td>
                        <th style={{ width: '17%' }}>
                            <Button color="danger" onClick={() => organizationInstance.DeleteOrganization(organization.id)}>Delete</Button>
                        </th>

                    </tr>
                ))}
                </tbody>
            </Table>

            
            <div className="d-flex justify-content-between align-items-center ml-5 mr-5">
                <div>Showing {(currentPage - 1) * pageSize} to {((currentPage - 1) * pageSize) + currentItems.length} of {llms.length} entries</div>

                <div>
                    <Pagination>
                    <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                    </PaginationItem>

                    {[...Array(totalPages)].map((_, index) => (
                        <PaginationItem key={index} active={index + 1 === currentPage}>
                        <PaginationLink onClick={() => setCurrentPage(index + 1)}>{index + 1}</PaginationLink>
                        </PaginationItem>
                    ))}

                    <PaginationItem disabled={currentPage === totalPages}>
                        <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                    </PaginationItem>
                    </Pagination>
                </div>
            </div>
        </div>
    )

    return (
        <>
            <PageCard cardName={"Settings"} cardBody={cardBody} />
        </>
    );
}

export default Settings;
