import { useEffect, useState } from "react"
import ReferencedDocumentsChart from "./ReferencedDocumentsChart";
import generateDummyReferences from "./dummyReferences";
import dayjs from 'dayjs';
import FromToDateSelector from "components/Forms/FromToDateSelector";
import { Alert, Button, CustomInput, Label } from "reactstrap";
import { useOrganization } from "hooks/organizationInstance";
import { defaultHeaders } from "components/auth/GetToken";
import axios from "components/axios/axios";
import { toast } from "react-toastify";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function ReferencedDocuments() {
    const organizationInstance = useOrganization();

    const [references, setReferences] = useState([]);
    const [fromDate, setFromDate] = useState(dayjs().subtract('6', 'day'))
    const [toDate, setToDate] = useState(dayjs())
    const [selectedPipeline, setSelectedPipeline] = useState('All');
    const [pipelines, setPipelines] = useState([]);
    const [helpOpen, setHelpOpen] = useState(false);

    async function fetchReferences() {
        const headers = await defaultHeaders();
        const pipelineToFetch = selectedPipeline === 'All' || !selectedPipeline ? undefined : selectedPipeline;

        if (!organizationInstance.organization) {
            return;
        }

        axios.get(`/referencedDocuments?organisationId=${organizationInstance.organization.id}&startDate=${fromDate.toISOString().split('T')[0]}&endDate=${toDate.endOf('day').toISOString()}${pipelineToFetch ? '&pipelineId=' + pipelineToFetch : ''}`, headers)
            .then((res) => {
                setReferences(res.data)
            })
            .catch((error) => {
                console.error(error);
                toast.error('Failed to fetch referenced documents')
            })
    }

    function fetchPipelinesForTenant() {
        if (organizationInstance.organization) {
            setPipelines([...organizationInstance.pipelines]);
        }
    }

    function handleSubmit() {
        fetchReferences();
    }

    useEffect(() => {
        fetchPipelinesForTenant();
        fetchReferences();
    }, [])

    if (!organizationInstance.organization) {
        return (
            <div className='p-3 m-0'>
                <Alert color='warning'><span class="alert-icon"><i class="fa-solid fa-triangle-exclamation"></i></span>Please select an organization</Alert>
            </div>
        )
    }

    return (
        <div className="MostReferencedDocuments">
            <div className="row p-3 m-0">
                <div className="col-lg-10">
                    <ReferencedDocumentsChart references={references} />
                </div>
                <div className='col-lg-2'>
                    <FromToDateSelector fromDate={fromDate} toDate={toDate} onFromDateChange={setFromDate} onToDateChange={setToDate} />
                    <Label for='pipeline'>Pipeline</Label>
                    <CustomInput
                        className='mb-3'
                        type='select'
                        id='pipeline'
                        onChange={(e) => setSelectedPipeline(e.target.value)}
                        value={selectedPipeline}
                    >
                        <option value='All'>All</option>
                        {pipelines.map(pipeline => <option key={pipeline.id} value={pipeline.id}>{pipeline.name}</option>)}
                    </CustomInput>
                    <Button onClick={handleSubmit} color="primary" className='w-100'>Filter</Button>
                    <Button onClick={() => setHelpOpen(!helpOpen)} color='secondary' size='sm' className='btn-icon w-100 ml-0 mt-3'>Help</Button>
                </div>
            </div>

            <Dialog maxWidth='sm' fullWidth open={helpOpen} onClose={() => { setHelpOpen(false) }}>
                <DialogTitle className="d-flex justify-content-between align-items-center">
                    Referenced documents help
                    <Button onClick={() => { setHelpOpen(false) }} color="danger px-3" size='sm'>X</Button>
                </DialogTitle>
                <DialogContent>
                    <p>Each bar represents the total amount of times a document has been referenced for a selected period of time.</p>
                    <p>The green and red bars do NOT represent ratings for the referenced documents themselves, but for the message that a document has been referenced in.</p>
                    <p>If a document has a mostly red bar, it means that the messages it has been referenced in have not been found helpful by the users</p>
                    <p><span className="font-weight-bold">Note: </span>A single message can reference multiple documents, so if a certain message has been given positive/negative feedback from the user, then this will be reflected in the chart for all the documents that have been referenced in the message.</p>
                </DialogContent>
            </Dialog>
        </div>
    )
}