import React, { useState } from 'react';
import { Card, CardHeader, Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, CardBody } from 'reactstrap';
import classnames from 'classnames';

// tabItems zal een array van objecten zijn, bijvoorbeeld:
// [{ tabId: '1', title: 'General', content: <GeneralContent /> }, { tabId: '2', title: 'Roles', content: <RolesContent /> }]
export const PageCardTabs = ({ cardName, tabItems }) => {
  const [activeTab, setActiveTab] = useState(tabItems[0].tabId);

  const toggleTab = (tabId) => {
    if (activeTab !== tabId) setActiveTab(tabId);
  }

  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col xl="12">
          <Nav tabs>
            {tabItems.map(item => (
              <NavItem key={item.tabId}>
                <NavLink
                  className={classnames({ active: activeTab === item.tabId })}
                  onClick={() => { toggleTab(item.tabId); }}
                >
                  {item.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col>
                  <h3 className="mb-0">{cardName}</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>

              <TabContent activeTab={activeTab}>
                {tabItems.map(item => (
                  <TabPane key={item.tabId} tabId={item.tabId} className={classnames({ active: activeTab === item.tabId })}>
                    {item.content}
                  </TabPane>
                ))}
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
