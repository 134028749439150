import { createContext, useContext, useState, useEffect } from 'react';
import { getTokenClaims, defaultHeaders } from "../components/auth/GetToken";
import { jwtDecode } from 'jwt-decode';
import { useAuth } from './authInstance';

import axios from "../components/axios/axios"

let permissionContext = createContext();
let token = await getTokenClaims();
let headers = await defaultHeaders();

const FetchData = async () => {
  token = await getTokenClaims();
  headers = await defaultHeaders();
}

export const getPermissions = async () => {
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {
      const response = await axios.get("/permission", headers);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  return null;
};

export const PermissionInstance = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    const fetchPermissions = async () => {
      if (isAuthenticated) {
        const token = sessionStorage.getItem('JWT');
        if (token) {
          try {
            const decoded = jwtDecode(token);
            setPermissions(decoded.permissions);
          } catch (error) {
            console.error('Failed to decode JWT token:', error);
          }
        }
      }
    };

    fetchPermissions();
  }, [isAuthenticated])

  const getPermissionsFromJWT = async () => {
    const token = sessionStorage.getItem('JWT');

    if (token) {
      try {
        const decoded = jwtDecode(token);
        return decoded.permissions
      } catch (error) {
        console.error('Failed to decode JWT token:', error);
      }
    }
  }

  return (
    <permissionContext.Provider value={{ permissions, setPermissions, getPermissionsFromJWT }}>
      {children}
    </permissionContext.Provider>
  );
};

export const usePermission = () => {

  if(permissionContext === null){
    permissionContext = createContext();
  }
  const context = useContext(permissionContext);
  if (!context) {
    throw new Error('usePermission must be used within a PermissionInstance');
  }
  return context;
};

