import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Button, FormGroup, Input, Table } from 'reactstrap';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Pagination } from '@mui/material';
import { defaultHeaders } from 'components/auth/GetToken';
import axios from 'components/axios/axios';

const preStyles = {
    whiteSpace: 'pre-wrap', 
    wordWrap: 'break-word', 
    width: '100%', 
    maxHeight: '200px'
}

function SystemLogsTable({ logs, totalPages, fetchLogs, page, setPage }) {
    const [selectedLogs, setSelectedLogs] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false)

    useEffect(() => {
        // Keep track if all logs are selected
        if (selectedLogs.length === logs.length && selectedLogs.length > 0) {
            setSelectAll(true);
        }
        else {
            setSelectAll(false);
        }
    }, [selectedLogs])

    // Refresh logs on page change
    useEffect(() => {
        fetchLogs(page)
    }, [page])

    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedLogs([]);
        } else {
            setSelectedLogs(logs.map(log => log.id));
        }
    };
    // Called when the small checkbox is clicked
    const toggleLogDeleteSelection = (logId) => {
        if (selectedLogs.includes(logId)) {
            setSelectedLogs(selectedLogs.filter(id => id !== logId));
        } else {
            setSelectedLogs([...selectedLogs, logId]);
        }
    };
    const openLogDetails = (log) => {
        setSelectedLog(log);
        setDialogOpen(true);
    };
    const handleDelete = async () => {
        const headers = await defaultHeaders();
        try {
            const url = `/logs/{ids}?${selectedLogs.map(id => `ids=${id}`).join('&')}`;
            await axios.delete(url, headers)
            setSelectedLogs([])
            // All logs on the page are deleted, go to previous one
            if (selectAll && page > 1) {
                // Setting the page automatically fetches the logs
                setPage(page - 1);
            }
            else{
                fetchLogs(page);
            }

        } catch (error) {
            console.error(error);
            toast.error('Failed to delete logs')
        }
    }
    const displayLogIcon = (type) => {
        switch (type) {
            case "info":
                return <i className="fa fa-info-circle text-info" aria-hidden="true"></i>
            case "warning":
                return <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
            case "error":
                return <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>
            default:
                return;
        }
    }
    return (
        <>
            <div className='TableHoverEffect'>
                <Table hover>
                    <thead className='bg-primary text-secondary'>
                        <th>
                            <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={toggleSelectAll}
                            />
                        </th>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Module</th>
                        <th>Content</th>
                        <th>Timestamp</th>
                    </thead>
                    <tbody>
                        {logs.map(log => {
                            return <tr onClick={() => openLogDetails(log)} key={log.id}>
                                <th>
                                    <FormGroup check>
                                        <Input
                                            type="checkbox"
                                            checked={selectedLogs.includes(log.id)}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => { toggleLogDeleteSelection(log.id) }}
                                        />
                                    </FormGroup>
                                </th>
                                <td>{log.id}</td>
                                <th scope='row'>{displayLogIcon(log.type)} {log.type}</th>
                                <td>{log.module}</td>
                                <td>{log.message.substring(0, 40)}...</td>
                                <td>{dayjs(log.createdAt).format('DD MMMM YYYY HH:mm')}</td>
                            </tr>
                        })}
                    </tbody>
                </Table>

                <div style={{ minHeight: '3rem' }} className={`d-flex justify-content-between align-items-center `}>
                    <Button disabled={selectedLogs.length <= 0} color='danger' onClick={handleDelete}>Delete Selected Logs</Button>
                    <Pagination count={totalPages} page={page} onChange={(event, value) => { setPage(value) }} />
                </div>

            </div>
            <Dialog maxWidth='sm' fullWidth open={dialogOpen} onClose={() => { setDialogOpen(false); setSelectedLog(null) }}>
                <DialogTitle className='d-flex justify-content-between align-items-center'>
                    System log details
                    <Button onClick={() => { setDialogOpen(false); setSelectedLog(null) }} color="danger px-3" size='sm'>X</Button>
                </DialogTitle>
                <DialogContent>
                    {selectedLog && <div>
                        <p><span className='font-weight-bold'>ID: </span>{selectedLog.id}</p>
                        <p><span className='font-weight-bold'>Type: </span>{displayLogIcon(selectedLog.type)} {selectedLog.type}</p>
                        <p><span className='font-weight-bold'>Module:</span> {selectedLog.module}</p>
                        <p><span className='font-weight-bold'>Organization:</span> {selectedLog.organization}</p>
                        <p><span className='font-weight-bold'>Timestamp:</span> {dayjs(selectedLog.createdAt).format('DD MMMM YYYY HH:mm')}</p>
                        <p><span className='font-weight-bold'>Expires at:</span> {dayjs(selectedLog.expiresAt).format('DD MMMM YYYY HH:mm')}</p>
                        <p className='mb-1'><span className='font-weight-bold'>Content</span> </p>
                        <Divider />
                        <pre className='mt-1' style={preStyles}>
                            {selectedLog.message}
                        </pre>
                    </div>}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default SystemLogsTable;
