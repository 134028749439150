import {useState} from "react";
import {Row, Col, Button as Btn, Container} from "reactstrap";
import {UniversalInput} from "../components/Forms/UniversalInput";

export const AddDataSource = ({addDataSourceSharepoint, existingSources}) => {
    let o = ["SharePoint", "SFTP"];
    const [selectedDataSource, setSelectedDataSource] = useState("SharePoint");
    const [sourceName, setSourceName] = useState("");

    // Sharepoint
    const [SPClientId, setSPClientId] = useState("")
    const [SPClientSecret, setSPClientSecret] = useState("")
    const [SPUrl, setSPUrl] = useState("")
    const [SPTenantId, setSPTenantId] = useState("")

    // FTP
    const [SFTPUrl, setSFTPUrl] = useState("")
    const [SFTPUserID, setSFTPUserID] = useState("")
    const [SFTPPassword, setSFTPPassword] = useState("")

    let AddDataSource = (existingSources) => {
        // Define the required fields for each source type
        const requiredFields = {
            "SharePoint": ['name', 'tenantId', 'clientId', 'clientSecret', 'sharepointUrl'],
            "SFTP": ['sourceName', 'url', 'userID', 'password']
        };

        if(selectedDataSource === "SFTP"){
            alert("Not implemented");
            return;
        } else if (selectedDataSource === "SharePoint") {
            const newSource = {
                organisationId: "",
                name: sourceName,
                tenantId: SPTenantId,
                clientId: SPClientId,
                clientSecret: SPClientSecret,
                sharepointUrl: SPUrl
            };

            const isUnique = !existingSources.includes(newSource.sourceName);
            const isValid = requiredFields[selectedDataSource].every(field => newSource[field]);
        
            if (isValid && isUnique) {
                addDataSourceSharepoint(newSource);
            } else if (!isUnique) {
                alert('The source name must be unique. Please choose a different name.');
            } else {
                alert('Please fill in all required fields for the selected source type.');
            }
        }
            
    };
    
    return <Container>
        <form>
            <div className="button-container mt-4">
                {o.map((e, t) => <Btn key={t} onClick={() => setSelectedDataSource(e)} color={selectedDataSource === e ? "primary" : "secondary"} className="button-item mr-2 mb-2">
                    {e}
                </Btn>)}
            </div>

            <h2 className="mt-4 mb-3">Data Source Configuration</h2>

            <UniversalInput id="InputModelName" label="Data source name (Unique)" setValue={setSourceName} value={sourceName} placeholder="Sharepoint1" fieldRequired={true} />

            {selectedDataSource === "SharePoint" && (
                <>
                    <Row>
                        <Col ><UniversalInput id="InputTenatId" label="Tenant ID" setValue={setSPTenantId} value={SPTenantId} placeholder="Tenant ID" fieldRequired={true}/></Col>
                    </Row>
                    <Row>
                        <Col ><UniversalInput id="InputClientId" label="Client ID" setValue={setSPClientId} value={SPClientId} placeholder="Client ID" fieldRequired={true}/></Col>
                    </Row>
                    <Row>
                        <Col ><UniversalInput id="InputClientSecret" label="Client Secret" setValue={setSPClientSecret} value={SPClientSecret} placeholder="Client Secret" fieldRequired={true} type={"password"}/></Col>
                    </Row>
                    <Row>
                        <Col ><UniversalInput id="InputSPUrl" label="SharePoint URL" setValue={setSPUrl} value={SPUrl} placeholder="SharePoint URL" fieldRequired={true}/></Col>
                    </Row>
                </>
            )}

            {selectedDataSource === "SFTP" && (
                <>
                    <Row>
                        <Col ><UniversalInput id="InputSFTPUrl" label="SFTP URL" setValue={setSFTPUrl} value={SFTPUrl} placeholder="SFTP URL" fieldRequired={true}/></Col>
                    </Row>
                    <Row>
                        <Col ><UniversalInput id="InputUserID" label="User ID" setValue={setSFTPUserID} value={SFTPUserID} placeholder="User ID" fieldRequired={true}/></Col>
                    </Row>
                    <Row>
                        <Col ><UniversalInput id="InputPassword" label="Password" setValue={setSFTPPassword} value={SFTPPassword} placeholder="Password" fieldRequired={true} type={"password"}/></Col>
                    </Row>
                </>
            )}

            <Btn color="primary" className="float-right ml-auto" type="button" onClick={e => {e.preventDefault(); AddDataSource(existingSources);}}>Save</Btn>
        </form>
    </Container>;
};
