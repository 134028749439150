import React, { useEffect, useState } from "react";
import { Table, Button, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { AddToken } from "./AddToken"
import { PageCard } from "../components/card/PageCard";
import { useOrganization } from "../hooks/organizationInstance";
import { OverlapCard } from "../components/card/OverlapCard";

const Tokens = () => {
    const organizationInstance = useOrganization();

    const [tokens, setTokens] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(15);
    const [addToken, setAddToken] = useState(false);

    useEffect(() => {
        if(organizationInstance.organization){
            setTokens(organizationInstance.tokens);
        }
    }, [organizationInstance, organizationInstance.tokens.length])

    useEffect(() => {
        const indexOfLastItem = currentPage * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;
        setCurrentItems(tokens?.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(tokens?.length / pageSize));
    }, [pageSize, currentPage, tokens])

    const handleDelete = (id) => {
        organizationInstance.DeleteToken(id);
    };

    const handleAdd = (tokenName, tokenCode, expirationDate) => {
        organizationInstance.AddToken(tokenName, tokenCode, expirationDate);
        setAddToken(false);
    };

    const cardBody = (
        <div style={{minHeight: '60vh', display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <Table>
                <thead>
                    <tr>
                    <th style={{ width: '55%' }}>Name</th>
                    <th style={{ width: '13%' }}>Token</th>
                    <th style={{ width: '13%' }}>Created Date</th>
                    <th style={{ width: '13%' }}>Expiration Date</th>
                    <th style={{ width: '17%' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item) => (
                    <tr key={item.name}>
                        <td style={{ width: '55%' }}>{item.name}</td>
                        <td style={{ width: '13%' }}>{item.displayToken}</td>
                        <td style={{ width: '13%' }}>{new Date(item.issueDate).toLocaleDateString()}</td> 
                        <td style={{ width: '13%' }}>{item.expirationDate && new Date(item.expirationDate).toLocaleDateString()}</td> 
                        <td style={{ width: '17%' }}>
                        <Button color="link p-0" style={{ color: 'red' }} onClick={() => handleDelete(item.id)}>
                            Revoke Access
                        </Button>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center ml-5 mr-5">
                <div>Showing {(currentPage - 1) * pageSize} to {((currentPage - 1) * pageSize) + currentItems?.length} of {tokens?.length} entries</div>

                <div>
                    <Pagination>
                    <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                    </PaginationItem>

                    {[...Array(totalPages)].map((_, index) => (
                        <PaginationItem key={index} active={index + 1 === currentPage}>
                        <PaginationLink onClick={() => setCurrentPage(index + 1)}>{index + 1}</PaginationLink>
                        </PaginationItem>
                    ))}

                    <PaginationItem disabled={currentPage === totalPages}>
                        <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                    </PaginationItem>
                    </Pagination>
                </div>
                { organizationInstance.organization != null &&
                    <Button onClick={() => setAddToken(true)}>Add </Button>
                }
            </div>
        </div>
    )
    const addTokenCardBody = <AddToken finishAddingToken={handleAdd}/>

    return (
        <>
            <PageCard cardName={"Tokens Configuration"} cardBody={cardBody} />
            <OverlapCard cardBody={addTokenCardBody} clickClose={() => setAddToken(false)} isOpen={addToken} />
        </>
    );
}

export default Tokens;
