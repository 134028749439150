import { useOrganization } from "../hooks/organizationInstance"
import React, { useState } from 'react';
import { Container, Button, Row, Col, Progress, Table, Input } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";

export const AddPipeline = ({finishAddingPipeline, existingPipelines}) => {
    const organizationInstance = useOrganization();
    React.useEffect(() => {
    }, [organizationInstance.dataSources, organizationInstance.llms]);


    const [currentPage, setCurrentPage] = useState(1);
    const [selectedDataSources, setSelectedDataSources] = useState([]);
    const [selectedLLM, setSelectedLLM] = useState(null);
    const [pipelineName, setPipelineName] = useState("");

    const totalPages = 3;

    const handleNext = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleBack = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleFinish = () => {
        const pipeline = {
            name: pipelineName,
            llmId: selectedLLM.id,
            dataSources: selectedDataSources.map((dataSource) => dataSource.id),
            organisationId: organizationInstance.organization.id,
        };
        finishAddingPipeline(pipeline);
    };

    const renderPageContent = () => {
        switch (currentPage) {
            case 1:
                return <DataSource handleNext={handleNext} selectedDataSources={selectedDataSources} setSelectedDataSources={setSelectedDataSources} dataSources={organizationInstance.dataSources}/>;
            case 2:
                return <LlmModels  handleNext={handleNext}  handleBack={handleBack} setSelectedLLM={setSelectedLLM} selectedLLM={selectedLLM} llms={organizationInstance.llms}/>;
            case 3:
                return <Finalization handleBack={handleBack} handleFinish={handleFinish} setPipelineName={setPipelineName} pipelineName={pipelineName} selectedLLM={selectedLLM} selectedDataSources={selectedDataSources} existingPipelines={existingPipelines}/>;
            default:
                return null;
        }
    };

    return (
        <Container>
            <Row className="mt-4">
                <Col>
                    <Progress value={(currentPage - 1) / (totalPages - 1) * 100} />
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    {renderPageContent()}
                </Col>
            </Row>
        </Container>
    );
}

const DataSource = ({handleNext, setSelectedDataSources, selectedDataSources, dataSources}) => {

    const [selectedDataSource, setSelectedDataSource] = useState("");

    const nextPage = () => {
        if(selectedDataSources.length < 1){
            alert("Make sure to select at least one data source!")
            return
        }
        handleNext()
    }

    const removeFromSelected = (dataname) => {
        const newSelectedDataSources = selectedDataSources.filter((dataSource) => dataSource.name !== dataname);
        setSelectedDataSources(newSelectedDataSources);
    }


    const addDataSource = () => {
        if (selectedDataSource === "") {
            return "Please select a data source.";
        }
        const existing = selectedDataSources.some((dataSource) => dataSource.name === selectedDataSource);
        if (existing) {
            return "Data source already selected.";
        }
        const indexToUpdate = dataSources.findIndex(dataSource => dataSource.name === selectedDataSource);
        if (indexToUpdate === -1) {
            return "Data source not found."; 
        }
        const newSelectedDataSources = [...selectedDataSources, dataSources[indexToUpdate]];
        setSelectedDataSources(newSelectedDataSources);
    }
    
    return (
        <Container>
            <Row className="mt-2">
                <Col>
                    <h2>Select data sources</h2>
                    <h4>Data source</h4>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                  
                    <Input
                        type="select"
                        value={selectedDataSource}
                        onChange={(e) => setSelectedDataSource(e.target.value)}
                    >
                        <option value="" disabled>Select data source</option>
                        {dataSources.map((dataSource, index) => (
                            <option key={index} value={dataSource.name}>
                                {dataSource.name} | {dataSource.configType}
                            </option>
                        ))}
                    </Input>

                </Col>
                <Col>
                    <Button color="primary" onClick={() => addDataSource()}>
                        Add data source
                    </Button>
                </Col>
                <Col></Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    <h4 className='mb-3'>Selection</h4>
                    <Table>
                        <thead>
                            <tr>
                            <th style={{ width: '55%' }}>Data source</th>
                            <th style={{ width: '13%' }}>Source</th>
                            <th style={{ width: '17%' }}>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedDataSources.map((item) => (
                            <tr key={item.name}>
                                <td style={{ width: '55%' }}>{item.name}</td>
                                <td style={{ width: '55%' }}>{item.configType}</td>
                                <td style={{ width: '17%' }}>
                                    <Button color="link" style={{ color: 'red' }} onClick={() => removeFromSelected(item.name)}>
                                        X
                                    </Button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <Button color="primary" className="ml-2 float-right ml-auto" onClick={nextPage}>
                        Next
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

const LlmModels = ({ handleNext, handleBack, setSelectedLLM, selectedLLM, llms }) => {
    const [selectedLLMId, setSelectedLLMId] = useState("")

    const nextPage = () => {
        if (selectedLLM === null) {
            alert('Make sure to select a LLM');
            return;
        }
        handleNext();
    };

    const lastPage = () => {
      handleBack();
    };

    const handleSelection = (llmIdValue) => {
        const llmId = Number(llmIdValue);
        if (!llmId) {
            return;
        }
        const indexToUpdate = llms.findIndex(llm => llm.id === llmId);
        if (indexToUpdate === -1) {
            return;
        }
        setSelectedLLM(llms[indexToUpdate]);
        setSelectedLLMId(llmId.toString());
    };
    
    

    const renderModelData = () => {
        switch (selectedLLM?.type) {
            case 'openai':
            return <>{openAIData()}</>;
            case 'Other':
            return <>{otherData()}</>;
            default:
            return null;
        }
    };

    const openAIData = () => {
        return (
            <Container>
                <Row className="mt-4">
                    <Col>
                        <UniversalInput id="llm" label="LLM" setValue={null} value={`${selectedLLM.id}|${selectedLLM.name}`} readonly={true}/>
                    </Col>
                    <Col>
                    </Col>
                    <Col></Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <UniversalInput id="temperature" label="Temperature" setValue={null} value={selectedLLM.config?.temperature} readonly={true}/>
                    </Col>
                    <Col>
                        <UniversalInput id="MaximumTokens" label="Maximum tokens" setValue={null} value={selectedLLM.config?.maximumTokens} readonly={true}/>
                    </Col>
                    <Col></Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <UniversalInput id="ApiKey" label="API Key" setValue={null} value={selectedLLM.config?.apiKey} readonly={true} type={"password"}/>
                    </Col>
                    <Col>
                        <UniversalInput id="topProbability" label="Top-P Probability" setValue={null} value={selectedLLM.config?.topProbability} readonly={true}/>
                    </Col>
                    <Col></Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <UniversalInput id="FrequencyPernalty" label="Frequency pernalty"  setValue={null} value={selectedLLM.config?.frequencyPenalty} readonly={true}/>
                    </Col>
                    <Col>
                        <UniversalInput id="PresencePernalty" label="Presence pernalty" setValue={null} value={selectedLLM.config?.presencePenalty} readonly={true}/>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        );
    };

    const otherData = () => {
        return (
            <Container>
                <Row className="mt-4">
                    <Col>
                        <UniversalInput id="llm" label="LLM" setValue={null} value={selectedLLM?.type} readonly={true}/>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <UniversalInput id="maximumTokens" label="Maximum Tokens" setValue={null} value={selectedLLM?.maximumTokens} readonly={true}/>
                    </Col>
                    <Col>
                        <UniversalInput id="API Key" label="API Key" setValue={null} value={selectedLLM?.apiKey} readonly={true} type={"password"}/>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        );
    };

    return (
        <Container>
            <Row className="mt-2">
                <Col>
                    <h2>Select Model</h2>
                    <h4>Model</h4>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Input
                        type="select"
                        value={selectedLLMId}
                        onChange={(e) => handleSelection(e.target.value)}
                    >
                        <option value="">
                            Select your model
                        </option>
                        {llms.map((llm, index) => (
                            <option key={index} value={llm.id}>
                                {llm.name}
                            </option>
                        ))}
                    </Input>
                </Col>
            </Row>

            <Row className="mt-4">
                <h2>Model settings preview</h2>
            </Row>

            <Row>
                <Col>{renderModelData()}</Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <Button color="primary" className="ml-2" onClick={lastPage}>
                        Back
                    </Button>
                    <Button color="primary" className="ml-2 float-right ml-auto" onClick={nextPage}>
                        Next
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

const Finalization = ({handleBack, handleFinish, setPipelineName, pipelineName, selectedLLM, selectedDataSources, existingPipelines}) => {
    const finish = () => {
        if(pipelineName === ""){
            alert("A name is required for the pipeline");
            return;
        }else if (existingPipelines.includes(pipelineName)){
            alert("A pipeline with this name already exists");
            return;
        }
        handleFinish();
    }
    const lastPage = () => {
        handleBack();
    }

    const dispalySelectedLLM = () => {
        switch (selectedLLM?.type) {
            case 'openai':
            return <>{openAIData()}</>;
            case 'other':
            return <>{otherData()}</>;
            default:
            return null;
        }
    };

    const openAIData = () => {
        return (
                <Container>
                    <Row>
                        <Col>
                            <Row><UniversalInput id="llm" label="LLM" setValue={null} value={`${selectedLLM.id}|${selectedLLM.name}`} readonly={true}/></Row>
                            <Row><UniversalInput id="temperature" label="Temperature" setValue={null} value={selectedLLM.config?.temperature} readonly={true}/></Row>
                            <Row><UniversalInput id="MaximumTokens" label="Maximum tokens" setValue={null} value={selectedLLM.config?.maximumTokens} readonly={true}/></Row>
                            <Row><UniversalInput id="ApiKey" label="API Key" setValue={null} value={selectedLLM.config?.apiKey} readonly={true} type="password"/></Row>
                        </Col>
                        <Col>
                            <Row><UniversalInput id="topProbability" label="Top-P Probability" setValue={null} value={selectedLLM.config?.topProbability} readonly={true}/></Row>
                            <Row><UniversalInput id="FrequencyPernalty" label="Frequency pernalty" setValue={null} value={selectedLLM.config?.frequencyPenalty} readonly={true}/></Row>
                            <Row><UniversalInput id="PresencePernalty" label="Presence pernalty" setValue={null} value={selectedLLM.config?.presencePenalty} readonly={true}/></Row>
                            <Row></Row>
                        </Col>
                    </Row>
                </Container>
        );
    };

    const otherData = () => {
        return (
            <Container>
                <Col>
                    <Row>
                        <UniversalInput id="llm" label="LLM" setValue={null} value={selectedLLM?.llm} readonly={true}/>
                    </Row>
                    <Row>
                        <UniversalInput id="maximumTokens" label="Maximum Tokens" setValue={null} value={selectedLLM?.maximumTokens} readonly={true}/>
                    </Row>
                    <Row>
                        <UniversalInput id="API Key" label="API Key" setValue={null} value={selectedLLM?.apiKey} readonly={true} type={"password"}/>
                    </Row>
                </Col>
            </Container>
        );
    };

    return (
        <Container>
            <Row className="mt-2">
                <Col>
                    <h2>Finalization</h2>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col>
                    <UniversalInput id="PipelineName" label="Pipeline name" setValue={setPipelineName} value={pipelineName} placeholder='pipeline name' required={true}/>
                </Col>
                <Col></Col>
                <Col></Col>
            </Row>
            <Row className="mt-2">
                 <Col>
                    <Row><h3>Chosen Data Sources:</h3></Row>
                    {selectedDataSources.map((item) => (
                        <Row>
                            <div key={item.name} style={{ marginBottom: '10px' }}>
                                <div>
                                    {`Name: ${item.name}`}
                                </div>
                                <div>
                                    {`Type: ${item.configType}`}
                                </div>
                            </div>
                        </Row>
                    ))}
                </Col>
                <Col>
                    <Row><h3>Chosen LLM Model:</h3></Row>
                    <Row>
                        {dispalySelectedLLM()}
                    </Row>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Button color="primary" className="ml-2" onClick={lastPage}>
                        Back
                    </Button>
                    <Button color="primary" className="ml-2 float-right ml-auto" onClick={finish}>
                        Finish
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}