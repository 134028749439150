import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from "../components/axios/axios"
import { defaultHeaders } from "../components/auth/GetToken"
import { useAuth } from './authInstance';

const OrganizationContext = createContext();

export const OrganizationInstance = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [pipelines, setPipelines] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [llms, setLlms] = useState([]);
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // get token
      const headers = await defaultHeaders()
      // send request

      await axios.get("/organisation", headers)
        .then(res => {
          setOrganizations(res.data)
          if (res.data.length > 0) {
            setOrganization(res.data[0])
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated])

  useEffect(() => {
    getAllTokens();
    getAllPipelines();
    getAllDataSources();
    getAllLlmByOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  // Organization
  const getAllOrganizations = async () => {
    const headers = await defaultHeaders()
    axios.get("/organisation", headers)
      .then(res => {
        setOrganizations(res.data)
      })
      .catch(error => {
        console.error(error);
      });
  }

  const AddOrganization = async (organizationName) => {
    const headers = await defaultHeaders()
    axios.post("/organisation", { name: organizationName }, headers)
      .then((res) => {
        getAllOrganizations();
        setOrganization(res.data)
      })
      .catch(error => {
        console.error(error);
      });
  }

  const DeleteOrganization = async (organizationId) => {
    const headers = await defaultHeaders()
    axios.delete(`/organisation/${organizationId}`, headers)
      .then((_) => {
        getAllOrganizations()
        const selectedOrganization = organizations.find(org => org.id !== organizationId) || null;;
        setOrganization(selectedOrganization);
      })
      .catch(error => {
        console.error(error);
      });
  }

  // LLM
  const AddLLMOpenAi = async (
    name,
    model,
    temperature,
    apiKey,
    topProbability,
    frequencyPenalty,
    presencePenalty,
    maximumTokens
  ) => {
    if (!organization) {
      return;
    }
    const headers = await defaultHeaders()
    const body = {
      organisationId: organization.id,
      name,
      model,
      temperature,
      apiKey,
      topProbability,
      frequencyPenalty,
      presencePenalty,
      maximumTokens
    }
    axios.post(`/llm/op`, body, headers)
      .then(async (_) => {
        await getAllLlmByOrganization();
        return true;
      })
      .catch(error => {
        alert(error.message);
        return false
      });
    return false
  }

  const DeleteLLM = async (llmId) => {
    if (!organization) {
      return;
    }
    const headers = await defaultHeaders()
    axios.delete(`/llm/${llmId}`, headers)
      .then(async (_) => {
        await getAllLlmByOrganization()
      })
      .catch(error => {
        console.error(error);
      });
  }

  const getAllLlmByOrganization = async () => {
    if (!organization) {
      return;
    }
    const headers = await defaultHeaders()
    axios.get(`/llm/${organization.id}`, headers)
      .then(res => {
        setLlms(res.data)
      })
      .catch(error => {
        console.error(error);
      });
  }

  // Data sources
  const AddDataSourceSharepoint = async (dataSource) => {
    if (!organization) {
      return;
    }
    const headers = await defaultHeaders();
    axios.post("/documentsource/sharepoint", dataSource, headers)
      .then(async (_) => {
        await getAllDataSources()
      })
      .catch(error => {
        console.error(error);
      });
  }

  const DeleteDataSource = async (dataSourceId) => {
    if (!organization) {
      return;
    }
    const headers = await defaultHeaders();
    axios.delete(`/documentsource/${dataSourceId}`, headers)
      .then(async (_) => {
        await getAllDataSources()
      })
      .catch(error => {
        console.error(error);
      });
  }

  const getAllDataSources = async () => {
    if (!organization) {
      return;
    }
    const headers = await defaultHeaders();
    axios.get(`/documentsource/${organization.id}`, headers)
      .then((res) => {
        setDataSources(res.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  // Pipeline
  const AddPipeline = async (newPipeline) => {
    if (!organization) {
      return
    }
    const body = { newPipeline }
    const headers = await defaultHeaders();
    axios.post("/pipeline", body, headers)
      .then((_) => {
        getAllPipelines();
      })
      .catch(error => {
        console.error(error);
      });
  }

  const DeletePipeline = async (pipelineId) => {
    const headers = await defaultHeaders();
    axios.delete(`/pipeline/${pipelineId}`, headers)
      .then((_) => {
        getAllPipelines();
      })
      .catch(error => {
        console.error(error);
      });
  }

  const getAllPipelines = async () => {
    if (!organization) {
      return;
    }
    const headers = await defaultHeaders();
    axios.get(`/pipeline/${organization.id}`, headers)
      .then((res) => {
        setPipelines(res.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  // Tokens
  const getAllTokens = async () => {
    if (!organization) {
      return;
    }
    const headers = await defaultHeaders();
    axios.get(`/accessToken/${organization.id}`, headers)
      .then((res) => {
        setTokens(res.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const AddToken = async (name, rawToken, expirationDate) => {
    if (!organization) {
      return
    }
    const organizationId = organization.id

    const body = { name, rawToken, organizationId, expirationDate }
    const headers = await defaultHeaders()
    axios.post("/accessToken", body, headers)
      .then((_) => {
        getAllTokens();
      })
      .catch(error => {
        console.error(error);
      });
  }

  const DeleteToken = async (id) => {
    const headers = await defaultHeaders();
    axios.delete(`/accessToken/${id}`, headers)
      .then((_) => {
        getAllTokens();
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <OrganizationContext.Provider value={{
      pipelines,
      dataSources,
      tokens,
      llms,
      organizations,
      setOrganizations,
      organization,
      setOrganization,
      getAllOrganizations,
      AddOrganization,
      DeleteOrganization,
      AddLLMOpenAi,
      DeleteLLM,
      AddDataSourceSharepoint,
      DeleteDataSource,
      AddPipeline,
      DeletePipeline,
      AddToken,
      DeleteToken,
    }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error('useOrganization must be used within a OrganizationInstance');
  }
  return context;
};
