import { truncateString } from "lib"
import { Table } from "reactstrap"

export default function UserFeedbackTable({ feedbacks, onClick }) {
    return (
        <>
            <Table hover className="TableHoverEffect">
                <thead className="bg-primary text-secondary">
                    <th>Conversation</th>
                    <th>Rating</th>
                    <th>Feedback</th>
                    <th>Message</th>
                    <th>Pipeline</th>
                    <th>Timestamp</th>
                </thead>
                <tbody>
                    {
                        feedbacks.map(feedback => {
                            return (
                                <tr onClick={() => onClick(feedback)} key={feedback.id}>
                                    <th scope="row"><pre className="d-inline">{truncateString(feedback?.Message?.conversationId, 8)}</pre></th>
                                    <td>
                                        {feedback?.rating === 5 && <i className="fa-regular fa-thumbs-up" style={{ color: 'green' }}></i>}
                                        {feedback?.rating === 0 && <i class="fa-regular fa-thumbs-down" style={{ color: 'red' }}></i>}
                                    </td>
                                    <td>{truncateString(feedback?.message, 15)}</td>
                                    <td>{truncateString(feedback?.Message?.question, 15)}</td>
                                    <td>{truncateString(feedback?.Message?.Pipeline?.name, 10)}</td>
                                    <td>{feedback?.createdAt}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            {
                feedbacks.length === 0 &&
                <div className="d-flex justify-content-center w-100">
                    <p>No feedback found</p>
                </div>
            }
        </>
    )
}