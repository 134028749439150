import React, { useEffect, useState } from "react";
import { Table, Button, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { AddDataSource } from "./AddDataSource"
import { PageCard } from "../components/card/PageCard";
import { useOrganization } from "../hooks/organizationInstance"
import { OverlapCard } from "../components/card/OverlapCard";
import { PermissionEnum } from "models/enums/permissionEnum";
import { usePermission } from 'hooks/permissionInstance';

function Llm() {
    const organizationInstance = useOrganization();

    const [dataSources, setDataSources] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(15);
    const [addDataSource, setAddDataSource] = useState(false);
    const permissionInstance = usePermission();

    useEffect(() => {
        if (organizationInstance) {
            setDataSources([...organizationInstance.dataSources]);
        }
    }, [organizationInstance, organizationInstance.dataSources.length]);

    useEffect(() => {
        const indexOfLastItem = currentPage * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;
        setCurrentItems(dataSources.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(dataSources.length / pageSize));
    }, [pageSize, currentPage, dataSources])

    const handleDelete = (dataSourceId) => {
        organizationInstance.DeleteDataSource(dataSourceId)
    };

    const handleAdd = (newLLM) => {
        newLLM["organisationId"] = organizationInstance.organization.id;
        organizationInstance.AddDataSourceSharepoint(newLLM)
        setAddDataSource(false)
    };

    const cardBody = (
        <div style={{ minHeight: '60vh', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Table>
                <thead>
                    <tr>
                        <th style={{ width: '55%' }}>Provider</th>
                        <th style={{ width: '13%' }}>Data Source</th>
                        <th style={{ width: '13%' }}>Created Date</th>
                        <th style={{ width: '17%' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item) => (
                        <tr key={item.name}>
                            <td style={{ width: '55%' }}>{item.configType} | {item.name}</td>
                            <td style={{ width: '13%' }}>{item.name}</td>
                            <td style={{ width: '13%' }}>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td style={{ width: '17%' }}>
                                <Button color="link p-0" style={{ color: 'red' }} onClick={() => handleDelete(item.id)}>
                                    Delete Data Source
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center ml-5 mr-5">
                <div>Showing {(currentPage - 1) * pageSize} to {((currentPage - 1) * pageSize) + currentItems.length} of {dataSources.length} entries</div>
                <div>
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                        </PaginationItem>

                        {[...Array(totalPages)].map((_, index) => (
                            <PaginationItem key={index} active={index + 1 === currentPage}>
                                <PaginationLink onClick={() => setCurrentPage(index + 1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        ))}

                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                        </PaginationItem>
                    </Pagination>
                </div>
                {permissionInstance.permissions.includes(PermissionEnum.CREATE_DATASOURCE) ? (
                    organizationInstance.organization != null ? (
                        <Button onClick={() => setAddDataSource(true)}>Add </Button>
                    ) : null
                ) : null}
            </div>
        </div>
    )
    const addLLMcardBody = <AddDataSource addDataSourceSharepoint={handleAdd} existingSources={dataSources.map(dataSource => dataSource.name)} />

    return (
        <>
            <PageCard cardName={"Active Data Sources"} cardBody={cardBody} />
            <OverlapCard cardBody={addLLMcardBody} clickClose={() => setAddDataSource(false)} isOpen={addDataSource} />
        </>
    );
};

export default Llm;
