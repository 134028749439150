import { useEffect, useState } from "react"
import { Line } from "react-chartjs-2"
import dayjs from 'dayjs';

export default function ApiTrafficGraph({logs, fromDate, toDate, showAllStacked = false, pipelines = []}) {

    const [lineChartData, setLineChartData] = useState(null);

    useEffect(() => {
        if (!logs) return;

        const dateLogs = new Map()

        // Initialize dateLogs with 0 for each day between fromDate and toDate
        let iterator = dayjs(fromDate)
        while (iterator.isBefore(toDate) || iterator.isSame(toDate)) {
            dateLogs.set(iterator.format("YYYY-MM-DD"), [])
            iterator = iterator.add(1, "day")
        }

        logs.forEach(log => {
            const date = log.createdAt.split("T")[0]
            if (dateLogs.has(date)) {
                dateLogs.set(date, [...dateLogs.get(date), log])
            } else {
                dateLogs.set(date, [log])
            }
        });

        const data = {
            labels: [...dateLogs.keys()],
            datasets: [
                {
                    label: "Total messages",
                    borderColor: "#5e72e4",
                    fill: false,
                    data: [...dateLogs.values().map(logs => logs.length)]
                }
            ]
        }

        if (showAllStacked) {
            const uniquePipelines = new Set(logs.map(log => log.pipelineId))

            if (uniquePipelines.size > 1) {
                for (const pipelineId of uniquePipelines) {
                    const pipelineObject = pipelines.find(pipeline => pipeline.id === pipelineId)
    
                    const pipelineData = {
                        label: pipelineObject.name || 'Unknown',
                        borderColor: pipelineObject.color,
                        fill: false,
                        data: [...dateLogs.values()].map(logs => logs.filter(log => log.pipelineId === pipelineId).length),
                    }
    
                    data.datasets.push(pipelineData)
                }

                data.datasets[0].label = "Total messages (all pipelines)"
            }
        }

        setLineChartData(data)
    }, [logs, showAllStacked])

    return (
        <div className="ApiTrafficGraph">
            {lineChartData && <Line data={lineChartData} />}
        </div>
    )
}