import React, { useEffect, useState } from "react";
import { Table, Button, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { AddLLM } from "./AddLLM"
import { PageCard } from "../components/card/PageCard";
import { useOrganization } from "../hooks/organizationInstance"
import { OverlapCard } from "../components/card/OverlapCard";
import { PermissionEnum } from "models/enums/permissionEnum";
import { usePermission } from 'hooks/permissionInstance';

function Llm() {
    const organizationInstance = useOrganization();

    const [llms, setllms] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(15);
    const [addLLM, setAddLLM] = useState(false);
    const permissionInstance = usePermission();

    useEffect(() => {
        if (organizationInstance.organization) {
            setllms([...organizationInstance.llms]);
        }
    }, [organizationInstance, organizationInstance.llms.length]);

    useEffect(() => {
        const indexOfLastItem = currentPage * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;
        setCurrentItems(llms.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(llms.length / pageSize));
    }, [pageSize, currentPage, llms])

    const handleDelete = (id) => {
        organizationInstance.DeleteLLM(id)
    };

    const handleAdd = () => {
        setAddLLM(false)
    };

    const cardBody = (
        <div style={{ minHeight: '60vh', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Table>
                <thead>
                    <tr>
                        <th style={{ width: '55%' }}>Provider</th>
                        <th style={{ width: '13%' }}>Model</th>
                        <th style={{ width: '13%' }}>Created Date</th>
                        <th style={{ width: '17%' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item) => (
                        <tr key={item.id}>
                            <td style={{ width: '55%' }}>{item.id} | {item.name}</td>
                            <td style={{ width: '13%' }}>{item.type}</td>
                            <td style={{ width: '13%' }}>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td style={{ width: '17%' }}>
                                <Button color="link p-0" style={{ color: 'red' }} onClick={() => handleDelete(item.id)}>
                                    Delete Model
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center ml-5 mr-5">
                <div>Showing {(currentPage - 1) * pageSize} to {((currentPage - 1) * pageSize) + currentItems.length} of {llms.length} entries</div>

                <div>
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                        </PaginationItem>

                        {[...Array(totalPages)].map((_, index) => (
                            <PaginationItem key={index} active={index + 1 === currentPage}>
                                <PaginationLink onClick={() => setCurrentPage(index + 1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        ))}

                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                        </PaginationItem>
                    </Pagination>
                </div>
                {permissionInstance.permissions.includes(PermissionEnum.CREATE_LLM) ? (
                    organizationInstance.organization != null ? (
                        <Button onClick={() => setAddLLM(true)}>Add</Button>
                    ) : null
                ) : null}

            </div>
        </div>
    )
    const addLLMcardBody = <AddLLM addLLM={handleAdd} existingModels={organizationInstance.llms.map(llm => llm.name)} />

    return (
        <>
            <PageCard cardName={"LLM Models"} cardBody={cardBody} />
            <OverlapCard cardBody={addLLMcardBody} clickClose={() => setAddLLM(false)} isOpen={addLLM} />
        </>
    );
};

export default Llm;
