import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import NavigationBar from "components/Navbars/NavigationBar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import { GetRoutesWithPermissions } from "../routes";
import { usePermission } from "hooks/permissionInstance";
import cy2_logo from "../assets/img/logos/CY2_logo.png";
import { useOrganization } from "hooks/organizationInstance";
import OrganizationNotFound from "layouts/OrganizationNotFound";
import { PermissionEnum } from "models/enums/permissionEnum";

function ProtectedLayout() {
    const { permissions } = usePermission();
    const [routes, setRoutes] = React.useState([]);
    const [isReady, setIsReady] = React.useState(false);

    React.useEffect(() => {
        if (permissions !== undefined) {
            const fetchedRoutes = GetRoutesWithPermissions(permissions);
            setRoutes(fetchedRoutes);
            setIsReady(true);
            console.log("Permissions updated:", permissions);
            console.log("Routes updated:", fetchedRoutes);
        }
    }, [permissions]);

    const [sidenavOpen, setSidenavOpen] = React.useState(true);
    const location = useLocation();
    const organizationInstance = useOrganization();

    const mainContentRef = React.useRef(null);

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (!organizationInstance.organization && (location.pathname !== '/logout' && location.pathname !== '/profile')) {
                return (
                    <Route 
                        path="*" 
                        element={<OrganizationNotFound />} 
                        key="organisation-not-found"
                        exact
                    />
                );
            } else {
                return (
                    <Route
                        path={prop.path}
                        element={prop.component}
                        key={prop.key}
                        exact
                    />
                );
            }
        });
    };

    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return "Brand";
    };

    const toggleSidenav = (e) => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        setSidenavOpen(!sidenavOpen);
    };

    const getNavbarTheme = () => {
        return location.pathname.indexOf("/alternative-dashboard") === -1 ? "dark" : "light";
    };

    return (
        <>
            {isReady && (
                <Sidebar
                    key={permissions.join('-')} // Force update Sidebar when permissions change
                    routes={routes}
                    toggleSidenav={toggleSidenav}
                    sidenavOpen={sidenavOpen}
                    logo={{
                        innerLink: "/",
                        imgSrc: cy2_logo,
                        imgAlt: "...",
                        className: "ml-auto"
                    }}
                />
            )}
            <div className="main-content" ref={mainContentRef}>
                {isReady && (
                    <NavigationBar
                        key={permissions.join('-')} // Force update NavigationBar when permissions change
                        theme={getNavbarTheme()}
                        toggleSidenav={toggleSidenav}
                        sidenavOpen={sidenavOpen}
                        brandText={getBrandText(location.pathname)}
                    />
                )}

                {isReady && (
                    <Routes>
                        {getRoutes(routes)}

                        <Route
                            path="*"
                            element={permissions.includes(PermissionEnum.READ_DASHBOARD) ? <Navigate to="/dashboard" replace /> : <Navigate to="/profile" replace />}
                        />
                    </Routes>
                )}
            </div>
            {sidenavOpen ? (
                <div className="backdrop d-xl-none" onClick={toggleSidenav} />
            ) : null}
        </>
    );
}

export default ProtectedLayout;
