import React, { createContext, useState, useEffect, useContext } from 'react';
import { isTokenExpired } from 'components/auth/GetToken';

// Create the context
let AuthContext = createContext();

// Create a provider component
export const AuthInstance = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [jwtToken, setJwtToken] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem('JWT');

    if (token) {
      if (!isTokenExpired(token)) {
        // Token exists and is not expired
        setJwtToken(token);
        setIsAuthenticated(true);
      } else {
        // Token exists but is expired
        sessionStorage.removeItem('JWT'); // Remove expired token
        setIsAuthenticated(false);
        console.log("Expired token removed:", token);
      }
    } else {
      // No token exists
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, jwtToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  console.log("AuthContext: ", AuthContext);
  if (AuthContext === null) {
    AuthContext = createContext();
  }
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a authProvider');
  }
  return context;
};
