import { truncateString } from "lib";
import { useEffect, useState } from "react"
import { Bar, HorizontalBar } from "react-chartjs-2";

const options = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
    responsive: true,
    scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    },
  };

export default function ReferencedDocumentsChart({ references, truncateFilenames = false }) {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (!references) return;

        // map filename -> reference[] god i wish there was typescript here
        const referenceCounts = new Map()

        references.forEach(reference => {
            if (referenceCounts.has(reference.filename) && referenceCounts.get(reference.filename).length > 0) {
                referenceCounts.set(reference.filename, [...referenceCounts.get(reference.filename), reference])
            } else {
                referenceCounts.set(reference.filename, [reference])
            }
        })

        const sortedCounts = [...referenceCounts.entries()].sort((a, b) => b[1].length - a[1].length);

        let sortedLabels = sortedCounts.map(entry => entry[0]);
        const sortedData = sortedCounts.map(entry => entry[1]);

        if (truncateFilenames) {
            sortedLabels = [...sortedLabels].map((label) => truncateString(label, 15));
        }

        const data = {
            labels: sortedLabels,
            datasets: [
                {
                    label: "Positive",
                    backgroundColor: '#2dce89',
                    data: sortedData.map(reference => {
                        return reference.filter(ref => ref.message.MessageFeedback[0]?.rating === 5).length || 0
                    })
                },
                {
                    label: "Negative",
                    backgroundColor: '#f5365c',
                    data: sortedData.map(reference => {
                        return reference.filter(ref => ref.message.MessageFeedback[0]?.rating === 0).length || 0
                    })
                },
                {
                    label: "No feedback",
                    backgroundColor: '#adb5bd',
                    data: sortedData.map(reference => {
                        return reference.filter(ref => ref.message.MessageFeedback.length === 0).length || 0
                    })
                },
            ]
        };

        setChartData(data);

    }, [references])

    return (
        <div className="ReferencedDocumentsChart">
            {chartData && <HorizontalBar data={chartData} options={options}/>}
        </div>
    )
}