import React, { useEffect, useState } from "react";
import { Table, Button, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { AddPipeline } from "./AddPipeline"
import { PageCard } from "../components/card/PageCard";
import { useOrganization } from "../hooks/organizationInstance";
import { OverlapCard } from "../components/card/OverlapCard";
import { useNavigate } from "react-router-dom";
import { PermissionEnum } from "models/enums/permissionEnum";
import { usePermission } from 'hooks/permissionInstance';

const Pipeline = () => {
    const organizationInstance = useOrganization();
    const navigate = useNavigate();

    const [pipelines, setPipelines] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(15);
    const [addPipeline, setAddPipeline] = useState(false);
    const permissionInstance = usePermission();

    useEffect(() => {
        if (organizationInstance.organization) {
            setPipelines([...organizationInstance.pipelines]);
        }
    }, [organizationInstance, organizationInstance.pipelines.length]);

    useEffect(() => {
        const indexOfLastItem = currentPage * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;
        setCurrentItems(pipelines.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(pipelines.length / pageSize));
    }, [pageSize, currentPage, pipelines])

    const handleDelete = (id) => {
        organizationInstance.DeletePipeline(id)
    };

    const handleAdd = (newPipeline) => {
        organizationInstance.AddPipeline(newPipeline);
        setAddPipeline(false);
    };

    const cardBody = (
        <div style={{ minHeight: '60vh', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Table>
                <thead>
                    <tr>
                        <th style={{ width: '55%' }}>Provider</th>
                        <th style={{ width: '13%' }}>Model</th>
                        <th style={{ width: '13%' }}>Sources</th>
                        <th style={{ width: '13%' }}>Created Date</th>
                        <th style={{ width: '17%' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item) => (
                        <tr key={item.name}>
                            <td style={{ width: '55%' }}>{item.id}|{item.name}</td>
                            <td style={{ width: '13%' }}>{item.LLM?.id} | {item.LLM?.name}</td>
                            <td style={{ width: '13%' }}>
                                {item.DocumentSourcesOnPipeline?.map((document) => (
                                    <div key={document.DocumentSource.id}>{document.DocumentSource.name} (<span className="small">{document.DocumentSource.configType}</span>)</div>
                                ))}
                            </td>
                            <td style={{ width: '13%' }}>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td style={{ width: '17%' }}>
                                <Button color="link p-0" onClick={() => navigate(`/chatbot/demo?pipelineId=${item.id}`)}>
                                    Test pipeline
                                </Button>
                                <Button color="link p-0" style={{ color: 'red' }} onClick={() => handleDelete(item.id)}>
                                    Delete Pipeline
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center ml-5 mr-5">
                <div>Showing {(currentPage - 1) * pageSize} to {((currentPage - 1) * pageSize) + currentItems?.length} of {pipelines?.length} entries</div>

                <div>
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                        </PaginationItem>

                        {[...Array(totalPages)].map((_, index) => (
                            <PaginationItem key={index} active={index + 1 === currentPage}>
                                <PaginationLink onClick={() => setCurrentPage(index + 1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        ))}

                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                        </PaginationItem>
                    </Pagination>
                </div>
                {permissionInstance.permissions.includes(PermissionEnum.CREATE_PIPELINE) ? (
                    organizationInstance.organization != null ? (
                        <Button onClick={() => setAddPipeline(true)}>Add </Button>
                    ) : null
                ) : null}
            </div>
        </div>
    )
    const addPipelineCardBody = <AddPipeline finishAddingPipeline={handleAdd} existingPipelines={organizationInstance.pipelines.map(pipeline => pipeline.name)} />

    return (
        <>
            <PageCard cardName={"Pipeline Configuration"} cardBody={cardBody} />
            <OverlapCard cardBody={addPipelineCardBody} clickClose={() => setAddPipeline(false)} isOpen={addPipeline} />
        </>
    );
}

export default Pipeline;
