import React, { useState } from "react"
import { Row, Col, Button, Container } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";
import { useOrganization } from "../hooks/organizationInstance"

export const AddLLM = ({addLLM, existingModels}) => {
    const organizationInstance = useOrganization();

    const availableLLMs = ["OpenAI", "Other"];

    const [selectedLLM, setSelectedLLM] = useState("OpenAI");
    const [modelName, setModelName] = useState("");
    const [temperature, setTemperature] = useState(1);
    const [apiKey, setAPIKey] = useState("");
    const [model, setModel] = useState("gpt-3.5-turbo");
    const [topProbability, setTopProbability] = useState(1);
    const [frequencyPenalty, setFrequencyPenalty] = useState(1);
    const [presencePenalty, setPresencePenalty] = useState(1);
    const [maximumTokens, setMaximumTokens] = useState(100);

    const AddNewLLM = async (event) => {  
        event.preventDefault()          
        if(existingModels.includes(modelName)){
            alert('The Model name must be unique. Please choose a different name.');
            return;
        }
        if(selectedLLM === "Other"){
            alert("Not Implemented");
            return;
        } else if(selectedLLM === "OpenAI"){
            await organizationInstance.AddLLMOpenAi(
                modelName,
                model,
                temperature,
                apiKey,
                topProbability,
                frequencyPenalty,
                presencePenalty,
                maximumTokens,
            );
        }
        addLLM();
    }   

    const renderInputs = () => {
        switch (selectedLLM) {
            case 'OpenAI':
            return <>{OpenAiInputs()}</>;
            case 'Other':
            return <>{OtherInputs()}</>;
            default:
            return null;
        }
    }

    const OpenAiInputs = () => {
        return(
            <Container>
                <Row>
                    <Col>
                        <UniversalInput id="Model" label="Model" setValue={setModel} value={model} placeholder="" fieldRequired={true} dropdown={true} options={["gpt-3.5-turbo", "gpt-4"]}/>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <UniversalInput id="InputTemperature" label="Temperature" setValue={setTemperature} value={temperature} placeholder={1} type="number" fieldRequired={true} min={0} max={2}/>
                    </Col>
                    <Col>
                        <UniversalInput id="InputMaximumTokens" label="Maximum tokens" setValue={setMaximumTokens} value={maximumTokens} placeholder={100} type="number" fieldRequired={true} min={1} max={4096}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <UniversalInput id="InputApiKey" label="API Key" setValue={setAPIKey} value={apiKey} placeholder="key" fieldRequired={true} type="password"/>
                    </Col>
                    <Col>
                        <UniversalInput id="InputTop-pProbability" label="Top-p Probability" setValue={setTopProbability} value={topProbability} placeholder={1} type="number" fieldRequired={true} min={0} max={1}/>
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <UniversalInput id="FrequencyPernalty" label="Frequency pernalty" setValue={setFrequencyPenalty} value={frequencyPenalty} placeholder={1} type="number" fieldRequired={true} min={0} max={2}/>
                    </Col>
                    <Col >
                        <UniversalInput id="PresencePernalty" label="Presence pernalty" setValue={setPresencePenalty} value={presencePenalty} placeholder={1} type="number" fieldRequired={true} min={0} max={2}/>
                    </Col>
                </Row>
            </Container>
        )
    }

    const OtherInputs = () => {
        return (
            <Container>
                <Row>
                    <Col >
                        <UniversalInput id="InputApiKey" label="API Key" setValue={setAPIKey} value={apiKey} placeholder="key" fieldRequired={true} type={"password"}/>
                    </Col>
                    <Col>
                        <UniversalInput id="InputMaximumTokens" label="Maximum tokens" setValue={setMaximumTokens} value={maximumTokens} placeholder="10000" fieldRequired={true}/>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container>
            <form onSubmit={(event) => AddNewLLM(event)}>
                <div className="button-container mt-4">
                    {availableLLMs.map((option, index) => (
                        <Button
                            key={index}
                            onClick={() => setSelectedLLM(option)}
                            color={selectedLLM === option ? "primary" : "secondary"}
                            className="button-item mr-2 mb-2"
                        >
                            {option}
                        </Button>
                    ))}
                </div>

                <h2 className="mt-4 mb-3">OpenAI Model Configuration</h2>

                <UniversalInput id="InputModelName" label="Model name (Unique)" setValue={setModelName} value={modelName} placeholder="Model Name" fieldRequired={true} />

                {renderInputs()}
                
                <Button color="primary" className="float-right ml-auto" type="submit">Save</Button>
            </form>
        </Container>
    )
}