import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import ApiTrafficGraph from './ApiTrafficGraph';
import FromToDateSelector from 'components/Forms/FromToDateSelector';
import { Alert, Button, CustomInput, Label } from 'reactstrap';
import { useOrganization } from 'hooks/organizationInstance';
import { defaultHeaders } from 'components/auth/GetToken';
import axios from 'components/axios/axios';
import { toast } from 'react-toastify';
import { StatCard } from 'pages/Overview';
import { cloneDeep } from 'lodash';
import { stringToColor } from 'lib';


export default function ApiTraffic() {
    const organizationInstance = useOrganization();

    const [logs, setLogs] = useState([]);
    const [fromDate, setFromDate] = useState(dayjs().subtract('6', 'day'))
    const [toDate, setToDate] = useState(dayjs())
    const [selectedPipeline, setSelectedPipeline] = useState('All');
    const [pipelines, setPipelines] = useState([]);
    const [showStacked, setShowStacked] = useState(true);

    const uniquePipelinesCount = useMemo(() => {
        if (!logs.length) {
            return 0;
        }

        return new Set(logs.map(log => log.pipelineId)).size;
    }, [logs])

    const averageDaily = useMemo(() => {
        if (!logs.length) {
            return 0;
        }

        const differenceInDays = Math.abs(toDate.diff(fromDate, 'day')) + 1;

        return +(logs.length / differenceInDays).toFixed(1);

    }, [logs])

    async function fetchLogs() {
        const headers = await defaultHeaders();
        const pipelineToFetch = selectedPipeline === 'All' || !selectedPipeline ? undefined : selectedPipeline;

        if (!organizationInstance.organization) {
            return;
        }

        axios.get(`/apiTraffic?organisationId=${organizationInstance.organization.id}&startDate=${fromDate.toISOString().split('T')[0]}&endDate=${toDate.endOf('day').toISOString()}${pipelineToFetch ? '&pipelineId=' + pipelineToFetch : ''}`, headers)
            .then((res) => {
                setLogs(res.data)
            })
            .catch((error) => {
                console.error(error);
                toast.error('Failed to fetch logs')
            })
    }

    function fetchPipelinesForTenant() {
        if (organizationInstance.organization) {
            const copy = cloneDeep(organizationInstance.pipelines);
            copy.forEach(pipeline => pipeline.color = stringToColor(pipeline.name.toString()));

            console.log(copy)

            setPipelines(copy);
        }
    }

    function handleSubmit() {
        fetchLogs();
    }


    useEffect(() => {
        const fetchData = async () => {
            fetchPipelinesForTenant();
            await fetchLogs();
        }
        fetchData();
    }, [organizationInstance])


    if (!organizationInstance.organization) {
        return (
            <div className='p-3 m-0'>
                <Alert color='warning'><span class="alert-icon"><i class="fa-solid fa-triangle-exclamation"></i></span>Please select an organization</Alert>
            </div>
        )
    }

    return (
        <div className="ApiTraffic">
            <div className='row p-3 m-0'>
                <div className='col-md-10'>
                    <ApiTrafficGraph logs={logs} fromDate={fromDate} toDate={toDate} showAllStacked={showStacked} pipelines={pipelines} />
                </div>
                <div className='col-md-2'>
                    <FromToDateSelector fromDate={fromDate} toDate={toDate} onFromDateChange={setFromDate} onToDateChange={setToDate} />
                    <Label for='pipeline'>Pipeline</Label>
                    <CustomInput
                        className='mb-3'
                        type='select'
                        id='pipeline'
                        onChange={(e) => setSelectedPipeline(e.target.value)}
                        value={selectedPipeline}
                    >
                        <option value='All'>All</option>
                        {pipelines.map(pipeline => <option key={pipeline.id} value={pipeline.id}>{pipeline.name}</option>)}
                    </CustomInput>
                    <Button onClick={handleSubmit} color="primary" className='w-100'>Filter</Button>
                    <div className='mt-3'>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck1" disabled={!(selectedPipeline === 'All' && uniquePipelinesCount > 1)} checked={showStacked && selectedPipeline === 'All'} onChange={() => setShowStacked(!showStacked)}/>
                            <label class="custom-control-label" for="customCheck1" title="This option is only avaiable when 'All' is selected in pipeline and more than 1 pipelines are present in your organization" >Show all stacked</label>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <StatCard title='Avg daily' value={averageDaily} icon='fa-solid fa-comment' />
                    </div>
                </div>
            </div>
        </div>
    )
}