import React, { useState, useEffect } from 'react';
import axios from "../components/axios/axios";
import { Button, Card, CardBody, Input } from 'reactstrap';
import {UniversalInput} from "../components/Forms/UniversalInput"

export const Chatbot = () => {
  const [pipelineId, setPipelineId] = useState(0);
  const [token, setToken] = useState("");
  const [question, setQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [processing, setProcessing] = useState(false);


  useEffect(() => {
    const getPipelineIdFromURL = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const pipelineIdFromParams = urlParams.get('pipelineId');
      setPipelineId(pipelineIdFromParams || 0);
    };
    getPipelineIdFromURL();
  }, [pipelineId]);

  const handleSendRequest = () => {
    const requestData = {
      "token": token,
      "question": question,
      "history": chatHistory,
    };
    
    setChatHistory(prevHistory => [...prevHistory, { "from": "user", "message": question }]);
    setProcessing(true)
    axios.post(`chatbot/${pipelineId}/message`, requestData)
        .then(response => {
            setChatHistory(prevHistory => [...prevHistory, { "from": "bot", "message": response.data.text }]);
            setQuestion('');
            setProcessing(false)
        })
        .catch(error => {
            console.error('Error sending request:', error)
            setProcessing(false)
        });
  };

  return (
    <CardBody >
      <UniversalInput id="API KEY" label="API KEY:" setValue={setToken} value={token} placeholder="token" fieldRequired={true} type="password"/>

      <h2>Chat History</h2>
        <CardBody style={{ display:"flex",overflowY: 'auto', height: "500px", flexDirection: 'column-reverse' }}>
        {processing && <p>Processing</p>}
          
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {chatHistory.map((chat, index) => (
              <Message key={index} {...chat} />
            ))}
          </ul>
        </CardBody>
        <form style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onSubmit={(e) => { e.preventDefault(); if (!question.trim()) return; handleSendRequest(); setQuestion('') }}>
          <Input type="text" style={{ width: "80vw" }} value={question} onChange={(e) => setQuestion(e.target.value)} disabled={!token}/>
          <Button style={{ width: "20vw" }} color='primary' type="submit">Send</Button>
        </form>
    </CardBody>
  );
};


export const Message = (chat) => {
  const message = chat.message;
  const [words, setWords] = useState(null);

  useEffect(() => {
    if (chat.from === "user") {
      setWords(message);
      return;
    }

    const raw_split = message.split(/\s+/);
    let index = 0;

    const addWord = () => {
      if (index < raw_split.length) {
        setWords((prevWords) => (prevWords ? prevWords + ' ' : '') + raw_split[index]);
        index++;
        const delay = Math.floor(Math.random() * (150));
        setTimeout(addWord, delay);
      } else {
        setWords(message);
      }
    };

    addWord();
  }, [chat.from, message]);

  return (
    <div style={{ display: 'flex', justifyContent: chat.from === "user" ? 'flex-end' : "flex-start" }}>
      <Card style={{ padding: '5px 25px', backgroundColor: chat.from === "bot" ? "#03cb" : "", color: chat.from === "bot" ? "white" : "" }}>
        {words && words.split('\n').map((line, i) => <div key={i}>{line}</div>)}
      </Card>
    </div>
  );
};
