import React from 'react';
import { useOrganization } from "../hooks/organizationInstance"
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
} from 'reactstrap';
import {PageCard} from "../components/card/PageCard"
import { useNavigate } from 'react-router-dom';

function AddOrganization() {
  const organizationInstance = useOrganization();
  const navigate = useNavigate();
  const [OrganizationName, setOrganizationName] = React.useState('');

  const AddOrganization = async (e) => {
      e.preventDefault();
      if(OrganizationName === ""){
        return;
      }

      organizationInstance.AddOrganization(OrganizationName)
      navigate('/dashboard');
  };

  const cardBody = (
    <Form>
      <h6 className="heading-small text-muted mb-4">Organization information</h6>
      <div className="pl-lg-4">
        <FormGroup className='col-4'>
          <Row>
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Organization Name
            </label>
          </Row>
          <Row>
            <Input
              onChange={(e) => setOrganizationName(e.target.value)}
              value={OrganizationName}
              className="form-control-alternative"
              id="input-username"
              placeholder="Organization Name"
              type="text"
            />
          </Row>
          <Row className="justify-content-end">
            <Button className='mt-2' type='submit' color="primary" onClick={(e) => {AddOrganization(e);}}>
              Save
            </Button>
          </Row>
        </FormGroup>
      </div>
    </Form>
  )

  return (
    <PageCard cardName={"Add Organization"} cardBody={cardBody} />
  );
}

export default AddOrganization;
