import React from "react";
import ReactDOM from "react-dom/client";
// react library for routing
import { BrowserRouter } from "react-router-dom";

// plugins styles from node_modules
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.1";

import { App } from "./app"

import { OrganizationInstance } from "hooks/organizationInstance";
import { RoleInstance } from "hooks/roleInstance";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PermissionInstance } from "hooks/permissionInstance";
import { AuthInstance } from "hooks/authInstance";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <AuthInstance>
        <RoleInstance>
            <PermissionInstance>
                <OrganizationInstance>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                    </LocalizationProvider>
                </OrganizationInstance>
            </PermissionInstance>
        </RoleInstance>
    </AuthInstance>
);
