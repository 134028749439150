import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";
import { PageCard } from "../components/card/PageCard";
import { PaginationLink } from "reactstrap";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { PageCardTabs } from '../components/card/PageCardTabs';
import { getPermissions } from "../hooks/permissionInstance"
import { MakeRole } from '../hooks/roleInstance';
import { useOrganization } from 'hooks/organizationInstance';


export const AddRole = () => {
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    const [permissions, setPermissions] = useState([]);
    const [modalPermissions, setModalPermissions] = useState([]);
    const [roleName, setRoleName] = useState('');

    const organisationId = useOrganization().organization.id;

    const [selectedPermissions, setSelectedPermissions] = useState([]);

    function addPermissions(permissionId) {
        setSelectedPermissions(prevPermissions => {
            if (!prevPermissions.includes(permissionId)) {
                return [...prevPermissions, permissionId];
            }
            return prevPermissions;
        });
    }

    function removePermissions(permissionId) {
        setSelectedPermissions(prevPermissions => prevPermissions.filter(id => id !== permissionId));
    }

    // useEffect for loading permissions from sessionStorage
    useEffect(() => {
        const fetchData = async () => {
            const storedPermissions = await getPermissions();
            if (storedPermissions) {
                setPermissions(storedPermissions);
            } else {
                console.log('Roles not found in sessionStorage.');
            }
        };
        fetchData(); // Call asynchronous function

    }, []); // This effect only pertains to loading the data, so it has no dependencies

    // useEffect for updating modalPermissions based on selectedPermissions
    useEffect(() => {
        const filteredPermissions = permissions.filter(permission => !selectedPermissions.includes(permission.id));
        setModalPermissions(filteredPermissions);
    }, [permissions, selectedPermissions]); // This effect updates modalPermissions based on the current state of permissions and selectedPermissions

    let navigate = useNavigate();

    const handleSave = async () => {
        const permissionIds = selectedPermissions.map(id => {
            const permission = permissions.find(p => p.id === id);
            return permission ? permission.id : 'Permission not found';
        });

        await MakeRole(roleName, organisationId, permissionIds);
        navigate('/role');
    };

    const General = (
        <Form>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="name">Role Name</Label>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Full Name"
                            value={roleName}
                            onChange={(e) => setRoleName(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                    <Label>Permission List ({selectedPermissions.length})</Label>
                    <Button className='mb-3' color="primary" onClick={toggleModal}>Add</Button>
                </div>
                <ListGroup className="flex-grow-1">
                    {selectedPermissions.map((currPermission, index) => {
                        const permissionInfo = permissions.find(role => role.id === currPermission);
                        return (
                            <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                                {permissionInfo ? permissionInfo.name : 'Permission not found'}
                                <Button className="btn-sm p-1" color="danger" onClick={() => removePermissions(permissionInfo.id)} aria-label="Remove permission">
                                    <i className="fas fa-trash"></i>
                                </Button>
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
                <Row>
                    <Col md={6} className="d-flex justify-content-start mt-5">
                        <Button color="success" onClick={handleSave}>Save</Button>
                    </Col>
                </Row>
            </FormGroup>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Available Permissions</ModalHeader>
                <ModalBody>
                    <ListGroup>
                        {modalPermissions.map((role, index) => (
                            <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                                {role.name}
                                <Button className="btn-sm p-1" color="primary" onClick={() => addPermissions(role.id)} aria-label="Add permission">
                                    <i className="fas fa-plus"></i>
                                </Button>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </Form>
    );

    return (
        <PageCard cardName="Create Role" cardBody={General} />
    );
}

export default AddRole;
