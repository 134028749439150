import FromToDateSelector from "components/Forms/FromToDateSelector";
import { useOrganization } from "hooks/organizationInstance";
import { useEffect, useMemo, useState } from "react";
import { Alert, Button, CustomInput } from "reactstrap";
import dayjs from 'dayjs';
import axios from "components/axios/axios";
import { defaultHeaders } from "components/auth/GetToken";
import { toast } from "react-toastify";
import UserFeedbackTable from "./UserFeedback/UserFeedbackTable";
import ApiTrafficGraph from "./ApiTraffic/ApiTrafficGraph";
import ReferencedDocumentsChart from "./ReferencedDocuments/ReferencedDocumentsChart";
import ConversationDialog from "components/Dialogs/ConversationDialog";
import { useLocation, useNavigate } from "react-router-dom";

export default function Overview() {
    const location = useLocation();
    const nav = useNavigate();

    const [loading, setLoading] = useState(false);

    const [selectedPipeline, setSelectedPipeline] = useState('All');

    const [pipelines, setPipelines] = useState([]);
    const organizationInstance = useOrganization();

    const [fromDate, setFromDate] = useState(dayjs().subtract('6', 'day'))
    const [toDate, setToDate] = useState(dayjs())

    const [feedbacks, setFeedbacks] = useState([]);
    const [logs, setLogs] = useState([]);
    const [references, setReferences] = useState([]);
    const [avgRating, setAvgRating] = useState('Not available');

    const latestMessage = useMemo(() => {
        if (!logs || logs.length === 0) return { question: 'Not available' };

        return logs[logs.length - 1];
    }, [logs])

    const pipelineToFetch = useMemo(() => {
        return selectedPipeline === 'All' || !selectedPipeline ? undefined : selectedPipeline;
    }, [selectedPipeline])

    function fetchPipelinesForTenant() {
        if (organizationInstance.organization) {
            setPipelines([...organizationInstance.pipelines]);
        }
    }

    async function fetchFeedbacks(headers) {
        return axios.get(`/messageFeedback?itemsPerPage=${5}&currentPage=${1}&organisationId=${organizationInstance.organization.id}&startDate=${fromDate.toISOString().split('T')[0]}&endDate=${toDate.endOf('day').toISOString()}${pipelineToFetch ? '&pipelineId=' + pipelineToFetch : ''}`, headers)
            .then((res) => {
                setFeedbacks(res.data.content)
            })
            .catch((error) => {
                toast.error('Failed to fetch feedbacks')
            })
    }

    async function fetchLogs(headers) {
        return axios.get(`/apiTraffic?organisationId=${organizationInstance.organization.id}&startDate=${fromDate.toISOString().split('T')[0]}&endDate=${toDate.endOf('day').toISOString()}${pipelineToFetch ? '&pipelineId=' + pipelineToFetch : ''}`, headers)
            .then((res) => {
                setLogs(res.data)
            })
            .catch((error) => {
                console.error(error);
                toast.error('Failed to fetch logs')
            })
    }

    async function fetchReferences(headers) {
        return axios.get(`/referencedDocuments?organisationId=${organizationInstance.organization.id}&startDate=${fromDate.toISOString().split('T')[0]}&endDate=${toDate.endOf('day').toISOString()}${pipelineToFetch ? '&pipelineId=' + pipelineToFetch : ''}`, headers)
            .then((res) => {
                setReferences(res.data)
            })
            .catch((error) => {
                console.error(error);
                toast.error('Failed to fetch referenced documents')
            })
    }

    function fetchAverageRating(headers) {
        return axios.get(`/messageFeedback/avg?organisationId=${organizationInstance.organization.id}&startDate=${fromDate.toISOString().split('T')[0]}&endDate=${toDate.endOf('day').toISOString()}${pipelineToFetch ? '&pipelineId=' + pipelineToFetch : ''}`, headers).then((res) => {
            setAvgRating(res.data.averageRating.toFixed(2))
        }).catch((error) => {
            console.error(error);
            toast.error('Failed to fetch average rating');
        })
    }

    async function fetchAll() {
        const headers = await defaultHeaders();

        if (!organizationInstance.organization) {
            return;
        }

        setLoading(true)

        Promise.allSettled([
            fetchFeedbacks(headers),
            fetchLogs(headers),
            fetchReferences(headers),
            fetchAverageRating(headers)
        ]).then(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchPipelinesForTenant();
    }, [organizationInstance])


    useEffect(() => {
        fetchAll();
        //eslint-disable-next-line
    }, [pipelineToFetch, fromDate, organizationInstance])

    if (!organizationInstance.organization) {
        return (
            <div className='p-3 m-0'>
                <Alert color='warning'><span className="alert-icon"><i className="fa-solid fa-triangle-exclamation"></i></span>Please select an organization</Alert>
            </div>
        )
    }

    return (
        <div className="Overview">
            <div className="p-3 m-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <CustomInput
                            type='select'
                            id='pipeline'
                            onChange={(e) => setSelectedPipeline(e.target.value)}
                            value={selectedPipeline}
                        >
                            <option value='All'>All pipelines in organization</option>
                            {pipelines.map(pipeline => <option key={pipeline.id} value={pipeline.id}>{pipeline.name}</option>)}
                        </CustomInput>
                    </div>
                    <div>
                        {
                            loading &&
                            <div className="d-flex align-items-center">
                                <div className="spinner-border text-primary mr-2" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div>Loading...</div>
                            </div>

                        }
                    </div>
                    <div>
                        <FromToDateSelector fromDate={fromDate} toDate={toDate} onFromDateChange={setFromDate} onToDateChange={setToDate} allowCustom={false} showLabel={false} noMargin={true} />
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-lg-6 p-0">
                        <ApiTrafficGraph logs={logs} fromDate={fromDate} toDate={toDate} />
                    </div>
                    <div className="col-lg-6 p-0 pt-3 pt-lg-0">
                        <ReferencedDocumentsChart references={references} truncateFilenames={true} />
                    </div>
                    <div className="col-lg-6 pt-3">
                        <div className="row">
                            <div className="col-lg-6 pr-2">
                                <StatCard title={'Average user rating'} icon="fa-solid fa-star" value={`${avgRating}/1`} />
                            </div>
                            <div className="col-lg-6 pl-2">
                                <StatCard title={'Messages exchanged'} icon="fa-solid fa-right-left" value={logs.length} />
                            </div>
                            <div className="col-lg-12">
                                <LatestQuestionStatCard title={'Latest asked question'} message={latestMessage} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pt-3" style={{ overflowX: 'scroll' }}>
                        <UserFeedbackTable feedbacks={feedbacks} onClick={() => {
                            if (location.pathname.startsWith('/dev/')) {
                                nav('/dev/user-feedback')
                                return;
                            }

                            if (location.pathname.startsWith('/admin/')) {
                                nav('/admin/user-feedback')
                                return;
                            }
                        }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export function StatCard({ title, value, color, icon = 'ni ni-chart-pie-35' }) {

    return (
        <div className="card mb-3 card-stats">
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <h5 className="card-title text-uppercase text-muted mb-0">{title}</h5>
                        <span className="h2 font-weight-bold mb-0">{value}</span>
                    </div>
                    <div className="col-auto">
                        <div className={`icon icon-shape bg-${color ?? 'primary'} text-white rounded-circle shadow`}>
                            {
                                icon &&
                                <i className={icon}></i>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function LatestQuestionStatCard({ title, message, color }) {

    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <>
            <div className="card card-stats">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title text-uppercase text-muted mb-0 mr-3">{title}  </h5>
                                <Button disabled={!message.conversationId} onClick={() => { setDialogOpen(true) }} color="secondary" size='sm'>View conversation</Button>
                            </div>
                            <span className="h2 font-weight-bold mb-0">{message?.question}</span>
                        </div>
                        <div className="col-auto">
                            <div className={`icon icon-shape bg-${color ?? 'primary'} text-white rounded-circle shadow`}>
                                <i className="fa-solid fa-comment"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConversationDialog open={dialogOpen} onClose={() => setDialogOpen(false)} conversationId={message.conversationId} />
        </>

    )
}