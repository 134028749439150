import React, { useState, useEffect } from 'react';
import { Container, Button, Pagination, PaginationItem, Row, Col, Progress, Table, Input } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";
import { PageCard } from "../components/card/PageCard";
import { PaginationLink } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { getRoles } from "../hooks/roleInstance"
import { CustomPageCard } from '../components/card/CustomPageCard';
import { PermissionEnum } from "models/enums/permissionEnum";
import { usePermission } from 'hooks/permissionInstance';

function getStatusStyle(status) {
    switch (status) {
        case 'Active':
            return { color: '#00FF00' }; // Brighter green
        case 'Pending':
            return { color: '#DAA520' }; // Darker yellow
        default:
            return { color: 'red' };
    }
}

export const UserManagement = ({ finishAddingPipeline, existingPipelines }) => {
    const [roles, setRoles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const permissionInstance = usePermission();

    useEffect(() => {
        const fetchData = async () => {

            // Try to fetch the roles from sessionStorage
            const storedRoles = await getRoles();

            if (storedRoles) {
                // If the roles are found, set them (after parsing to an object)
                setRoles(storedRoles);
            } else {
                // If the roles are not in sessionStorage, make an API request
                console.log("no roles found in sessionStorage");
            }
        };
        fetchData(); // Call the asynchronous function

    }, []);


    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;

    const currentUsers = roles
        .filter(user => user.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(roles.length / usersPerPage);
    const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
    const [sortedColumn, setSortedColumn] = useState('');

    const searchInput = (
        <Input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
        />
    );

    let navigate = useNavigate();

    const navigateToEditUser = (role) => {
        navigate('/edit-role', { state: { role } });
    };
    const navigateToAddRole = () => {
        navigate('/add-role');
    };

    const sortUsers = (column) => {
        const sortedUsers = [...roles].sort((a, b) => {
            let itemA = a[column];
            let itemB = b[column];

            // For dates, you may need to create a Date object for correct comparison
            if (column === 'permissionsAmount') {
                itemA = a.permissions.length;
                itemB = b.permissions.length;
            } else if (column === 'dateAdded') {
                itemA = itemA.split('-').reverse().join('-');
                itemB = itemB.split('-').reverse().join('-');
                itemA = new Date(itemA);
                itemB = new Date(itemB);
            } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                // Direct numeric comparison without changes needed
                return sortDirection === 'asc' ? itemA - itemB : itemB - itemA;
            } else {
                // Ensure comparison is case insensitive for strings
                itemA = typeof itemA === 'string' ? itemA.toUpperCase() : itemA;
                itemB = typeof itemB === 'string' ? itemB.toUpperCase() : itemB;
            }

            // Now compare the items as normal strings or numbers
            if (itemA < itemB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (itemA > itemB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });


        setRoles(sortedUsers);
        // Toggle the sort direction for next time
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        // Update the sorted column
        setSortedColumn(column);
    };


    const cardBody = (
        <div style={{ minHeight: '60vh', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Table>
                <thead>
                    <tr>
                        <th style={{ width: '65%', cursor: 'pointer' }} onClick={() => sortUsers('name')}>
                            Name {sortedColumn === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th style={{ width: '23%', cursor: 'pointer' }} onClick={() => sortUsers('permissionsAmount')}>
                            Permissions amount {sortedColumn === 'permissionsAmount' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th style={{ width: '17%' }}></th>
                    </tr>

                </thead>
                <tbody>
                    {currentUsers.map((role, index) => (
                        <tr key={index}>
                            <td>{role.name}</td>
                            <td>{role.permissions.length}</td>
                            {permissionInstance.permissions.includes(PermissionEnum.UPDATE_ROLE) ? ( //TODO check permission

                                <td>
                                    <Button onClick={() => navigateToEditUser(role)}>Edit Role</Button>
                                </td>
                            ) : (
                                <td></td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table >

            <div className="d-flex justify-content-between align-items-center ml-5 mr-5">
                <div>Showing {((currentPage - 1) * usersPerPage) + 1} to {((currentPage - 1) * usersPerPage) + currentUsers.length} of {roles.length} entries</div>

                <div>
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                        </PaginationItem>

                        {[...Array(totalPages)].map((_, index) => (
                            <PaginationItem key={index} active={index + 1 === currentPage}>
                                <PaginationLink onClick={() => setCurrentPage(index + 1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        ))}

                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                        </PaginationItem>
                    </Pagination>
                </div>
                {/* <Button className='mb-3' color="primary" onClick={() => navigateToAddRole()}>
                    Add
                </Button> */}
            </div>
        </div>
    )

    return (
        <CustomPageCard cardName={"Role Overview"}
            cardBody={cardBody}
        // searchBar={searchInput}

        >
            {permissionInstance.permissions.includes(PermissionEnum.CREATE_ROLE) && ( //TODO check permission
                <Button className='mb-3' color="primary" onClick={() => navigateToAddRole()}>
                    Add
                </Button>
            )}

            {searchInput}
        </CustomPageCard>


    );
}

export default UserManagement;
