import { config, msalInstance } from "../authConfig";
import React from 'react';
import {
    Button,
    Container,
    Row,
    Col,
} from "reactstrap";
 
import microsoft_logo from "../assets/img/logos/microsoft-1.png"
import mask_group from "../assets/img/bg/mask-group.png"
import { useNavigate } from "react-router-dom";
import { useRole } from "../hooks/roleInstance";
import { useOrganization } from "hooks/organizationInstance";
import { GetJwtToken } from "../hooks/roleInstance"
import { useAuth } from "hooks/authInstance";
import { usePermission } from "hooks/permissionInstance";
import { PermissionEnum } from "models/enums/permissionEnum"; 

const SignIn = () => {
    const navigate = useNavigate();
    const roleInstance = useRole();
    const organisationInstance = useOrganization();
    const authInstance = useAuth();
    const permissionInstance = usePermission();
 
    const handleLogin = async () => {
        try {
            // Sign in popup
            const msal = await msalInstance()
            const response = await msal.loginPopup({
                scopes: config.scopes,
                prompt: "select_account",
            });
            console.log("login response: ", response);

            const accounts = msal.getAllAccounts();
            if (accounts.length === 0) return;
            
            msal.setActiveAccount(accounts[0]);

            await roleInstance.AddUser(); //setting the userId in the sessionstorage
            await GetJwtToken(); //setting the jwt in the sessionstorage
            await authInstance.setIsAuthenticated(true);
            await roleInstance.GetRole();
            await organisationInstance.getAllOrganizations();

            const permissions = await permissionInstance.getPermissionsFromJWT();
            const hasReadDashboardPermission = permissions.includes(PermissionEnum.READ_DASHBOARD);

            if (hasReadDashboardPermission) {
                navigate("/dashboard");
            } else {
                navigate("/profile");
            }

        }
        catch (err) {
            console.error(err);
        }
    };
 
    return (
 
        <Container fluid className="text-center pl-0 overflow-hidden">
            <img
                className="w-full"
                alt="Background"
                src={mask_group}
            />
            <Row className="mt-8">
                <Col>
                    <h1 className="text-4xl font-bold">Sign in</h1>
                    <p className="text-lg">Sign in to access the FAQ management system</p>
                </Col>
            </Row>
            <Row className="mt-6">
                <Col>
                    <Button onClick={() => handleLogin()} className="bg-info text-white font-bold py-3 px-6 rounded">
                        <img
                            className="mr-2 width-2 height-2"
                            src={microsoft_logo}
                            alt="microsoft logo"
                        />
                        Login with Microsoft
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};
export default SignIn;