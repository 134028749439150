import React, { useState, useEffect } from 'react';
import { Container, Button, Pagination, PaginationItem, Row, Col, Progress, Table, Input, FormGroup } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";
import { PageCard } from "../components/card/PageCard";
import { PaginationLink } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ApproveUser, RejectUser } from '../hooks/userInstance';

function getStatusStyle(hasAccess, locked) {
    if (hasAccess && !locked) {
        return { color: '#00FF00' }; // Brighter green
    }
    else if (!hasAccess && !locked) {
        return { color: '#DAA520' }; // Darker yellow
    }
    else if (!hasAccess && locked) {
        return { color: 'red' };
    }
}

export const UserManagement_Pending = () => {
    const location = useLocation();
    const { pendingUsers } = location.state;

    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState(''); // State for the search term


    useEffect(() => {
        // Set 'users' with 'pendingUsers' once the component loads
        if (pendingUsers) {
            setUsers(pendingUsers);
        }
    }, [pendingUsers]);

    const searchInput = (
        <Input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
        />
    );


    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;

    const currentUsers = users
        .filter(user =>
        (user.userDetails.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.userDetails.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        .slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(users.length / usersPerPage);
    const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
    const [sortedColumn, setSortedColumn] = useState('');


    let navigate = useNavigate();

    const navigateToEditUser = (user) => {
        navigate('/edit-user', { state: { user } });
    };

    const ApprovePending = async () => {
        await ApproveUser(selectedUsers);
        navigate('/user');
    }

    const RejectPending = async () => {
        await RejectUser(selectedUsers);
        navigate('/user');
    }

    const sortUsers = (column) => {
        const sortedUsers = [...users].sort((a, b) => {
            let itemA = a[column];
            let itemB = b[column];

            // For dates, you may need to create a Date object for correct comparison
            if (column === 'dateAdded') {
                itemA = itemA.split('-').reverse().join('-');
                itemB = itemB.split('-').reverse().join('-');
                itemA = new Date(itemA);
                itemB = new Date(itemB);
            } else {
                // Ensure comparison is case insensitive for strings
                itemA = typeof itemA === 'string' ? itemA.toUpperCase() : itemA;
                itemB = typeof itemB === 'string' ? itemB.toUpperCase() : itemB;
            }

            if (itemA < itemB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (itemA > itemB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setUsers(sortedUsers);
        // Toggle the sort direction for next time
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        // Update the sorted column
        setSortedColumn(column);
    };
    const [selectedUsers, setSelectedUsers] = useState([]);


    const handleSelectChange = (userId) => {
        setSelectedUsers(prevSelected => {
            if (prevSelected.includes(userId)) {
                // If the user is already selected, remove them
                return prevSelected.filter(id => id !== userId);
            } else {
                // Otherwise add the user to the selected ones
                return [...prevSelected, userId];
            }
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            // Select all users
            setSelectedUsers(pendingUsers.map(user => user.id));
        } else {
            // Deselect all users
            setSelectedUsers([]);
        }
    };

    const cardBody = (
        <div style={{ minHeight: '60vh', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

            <Table>
                <thead>
                    <tr>
                        <th>
                            {/* Optional checkbox to select/deselect all rows */}
                            <input
                                type="checkbox"
                                checked={selectedUsers.length === pendingUsers.length}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th style={{ width: '45%', cursor: 'pointer' }} onClick={() => sortUsers('name')}>
                            Name {sortedColumn === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th style={{ width: '23%', cursor: 'pointer' }} onClick={() => sortUsers('email')}>
                            Email {sortedColumn === 'email' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th style={{ width: '17%', cursor: 'pointer' }} onClick={() => sortUsers('dateAdded')}>
                            Date Added {sortedColumn === 'dateAdded' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th style={{ width: '17%', cursor: 'pointer' }} onClick={() => sortUsers('status')}>
                            Status {sortedColumn === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentUsers.map((user, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedUsers.includes(user.id)} // Assumes each user has a unique 'id'
                                    onChange={() => handleSelectChange(user.id)}
                                />
                            </td>
                            <td>{user.userDetails.firstName}</td>
                            <td>{user.userDetails.email}</td>
                            <td>{user.userDetails.modifiedDate}</td>
                            <td style={getStatusStyle(user.userDetails.hasAccess, user.userDetails.locked)}>
                                {user.userDetails.locked ? 'Inactive' :
                                    user.userDetails.hasAccess ? 'Active' : 'Pending'}
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>
            <FormGroup>
                <Row>
                    <Col md={6} className="d-flex justify-content-start">
                        <Button color="success" disabled={selectedUsers.length === 0} onClick={ApprovePending}>Approve</Button>
                    </Col>
                    <Col md={6} className="d-flex justify-content-end">
                        <Button color="danger" disabled={selectedUsers.length === 0} onClick={RejectPending}>Disapprove</Button>
                    </Col>
                </Row>
            </FormGroup>


            <div className="d-flex justify-content-between align-items-center ml-5 mr-5">
                <div>Showing {((currentPage - 1) * usersPerPage) + 1} to {((currentPage - 1) * usersPerPage) + currentUsers.length} of {users.length} entries</div>

                <div>
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                        </PaginationItem>

                        {[...Array(totalPages)].map((_, index) => (
                            <PaginationItem key={index} active={index + 1 === currentPage}>
                                <PaginationLink onClick={() => setCurrentPage(index + 1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        ))}

                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                        </PaginationItem>
                    </Pagination>
                </div>
            </div>
        </div>
    )

    return (
        <PageCard
            cardName={"Pending Overview"}
            cardBody={cardBody}
            searchBar={searchInput}

        />
    )
}

export default UserManagement_Pending;
