import React from 'react';
import { FormGroup, Label, Input, CustomInput } from 'reactstrap';

export const UniversalInput = ({
  id,
  setValue,
  value,
  type = 'text',
  placeholder = '',
  label = null,
  fieldRequired = false,
  readonly = false,
  min = 0,
  max = 0,
  dropdown = false,
  noMargin = false,
  options = [],
}) => {
  const defaultInputClasses = 'border border-secondary rounded';

  return (
    <FormGroup className={`${noMargin && 'mb-0'}`}>
      {label !== null && <Label for={id}>{label}</Label>}
      {dropdown ? (
        <CustomInput
          type="select"
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
          required={fieldRequired}
          readOnly={readonly}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </CustomInput>
      ) : (
        <Input
          className={defaultInputClasses}
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required={fieldRequired}
          readOnly={readonly}
          min={min}
          max={max}
        />
      )}
    </FormGroup>
  );
};
