import { msalInstance } from "../../authConfig"
import { jwtDecode } from "jwt-decode";

export const getTokenClaims = async () => {
    try {
        const msal = msalInstance()
        
        const account = (await msal).getActiveAccount();
        if (!account) return null;
        return account.idTokenClaims
    } catch (error) {
        console.error('Error acquiring token:', error);
    }
    return null
}

export const getFullToken = async () => {
    try {
        const msal = msalInstance()

        const account = (await msal).getActiveAccount();
        if (!account) return null;
        return account
    } catch (error) {
        console.error('Error acquiring token:', error);
    }
    return null
}

export const getAccessToken = async () => {
    const customJWT = sessionStorage.getItem('JWT');

    if (customJWT) {
        return customJWT;
    }
    else {
        try {
            const msal = msalInstance()

            const account = (await msal).getActiveAccount();
            console.log("GetTokenPage - account: ", account);
            if (!account) throw new Error('No accounts signed in.');
            return account.idToken
        } catch (error) {
            console.error('Error acquiring token:', error);
        }
        return null
    }
}

export const defaultHeaders = async () => {
    const accessToken = await getAccessToken();
    const headers = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }
    return headers
}

export const isTokenExpired = (token) => {
    if (!token) return true; // Token does not exist
    try {
        const decodedToken = jwtDecode(token);
        if (!decodedToken.exp) return true; // Expiration claim does not exist
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        return Date.now() > expirationTime; // Check if current time is after expiration time
    } catch (error) {
        console.error("Invalid token:", error);
        return true; // Token is invalid or malformed
    }
};