import React, { createContext, useContext, useEffect, useState } from 'react';
import {getTokenClaims, defaultHeaders, getFullToken} from "../components/auth/GetToken";
import { jwtDecode } from 'jwt-decode';

import axios from "../components/axios/axios"
import tokens from "../pages/Tokens";

const RoleContext = createContext();
let token = await getTokenClaims();
let headers = await defaultHeaders();

export const RoleInstance = ({ children }) => {
  const [role, setRole] = useState(null);
  const [roles, setRoles] = useState([]);

  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    // Assuming you have the JWT token stored somewhere, e.g., localStorage
    const token = sessionStorage.getItem('JWT');

    if (token) {
      try {
        const decoded = jwtDecode(token);
        setDecodedToken(decoded);
      } catch (error) {
        console.error('Failed to decode JWT token:', error);
      }
    }
  }, [role]);

  useEffect(() => {
    GetRole()
  }, [])

  const GetRole = async () => {
    const token = await getTokenClaims();
    if (token === null) {
      setRole(null)
    } else {
      setRole(token?.roles[0])
      setRoles(token?.roles)
    }
  }

  const AddUser = async () => {
    const token = await getFullToken();
    const headers = await defaultHeaders();

    if (token) {
      const fullName = token.name;

      const parts = fullName?.split(',');
      let lastName = parts[0]?.trim();
      const otherParts = parts[1]?.trim();
      const nameParts = otherParts?.split(' ');
      let firstName;
      if(nameParts){
        firstName = nameParts[0];
      }
      const initialsPattern = /^[A-Z](\.[A-Z])*(\.)?$/;
      const foundMiddleNames = nameParts?.slice(1).filter(part => !initialsPattern.test(part));
      const middleNames = foundMiddleNames?.join(' ');

      if(middleNames !== ' ')
      {
        lastName = middleNames + ' ' + lastName
      }
      if(!firstName){
        firstName = lastName;
      }

      await axios.post("/user", { identifier: token.idTokenClaims.sub, email: token.idTokenClaims.preferred_username, lastName: lastName, firstName: firstName }, headers)
        .catch(error => {
          console.error(error);
        });
    }
  }

  return (
    <RoleContext.Provider value={{ role, roles, GetRole, AddUser }}>
      {children}
    </RoleContext.Provider>
  );
};

const FetchData = async () => {
  token = await getTokenClaims();
  headers = await defaultHeaders();
}

export const GetJwtToken = async () => {
  const token = await getTokenClaims();
  const headers = await defaultHeaders();
  await axios.get("/user/login/" + token.sub, headers).then(response => {
    sessionStorage.setItem('JWT', response.data.token);
    const decodedJWT = jwtDecode(response.data.token);
    sessionStorage.setItem('userId', decodedJWT.userId);
  }).catch(error => {
    console.error(error);
  });
}

export const useRole = () => {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error('useRole must be used within a RoleInstance');
  }
  return context;
};

export const getRoles = async () => {
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {
      const response = await axios.get("/role", headers);
      return response.data; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const getRole = async (id) => {
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {
      const response = await axios.get("/role/" + id, headers);
      return response.data; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const UpdateRole = async (roleId, roleName, organisationId, permissionIds) => {
  console.log("org id", organisationId);
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {
      const userid = sessionStorage.getItem('userId');

      let body = {
        "id": roleId,
        "name": roleName, // Gebruik direct de variabele zonder string template
        "modifiedByUserId": userid,
        "organisationId": organisationId,
        "permissions": permissionIds // Gebruik de verzamelde permissionIds array
      };
      const response = await axios.patch("/role/", body, headers);
      return response; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const DeleteRole = async (id) => {
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {
      const response = await axios.delete("/role/" + id, headers);
      return response.data; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
};

export const MakeRole = async (roleName, organisationId, permissionIds) => {
  if (token === null) {
    await FetchData();
  }
  if (token !== null) {
    try {
      const userid = sessionStorage.getItem('userId');

      let body = {
        "name": roleName, // Gebruik direct de variabele zonder string template
        "createdByUserId": userid,
        "modifiedByUserId": userid,
        "organisationId": organisationId,
        "permissions": permissionIds // Gebruik de verzamelde permissionIds array
      };

      const response = await axios.post("/role/", body, headers);
      return response.data; // Geeft de response data terug
    } catch (error) {
      console.error(error);
      return null; // Of een meer specifieke foutafhandeling
    }
  }
  return null; // In geval token niet beschikbaar is
}

// export const GetJwtToken = async () => {
//   const context = useContext(RoleContext);
//   if (!context) {
//     throw new Error('useRole must be used within a RoleInstance');
//   }
//   return context;
// };