import { PublicClientApplication } from '@azure/msal-browser';


export const config = {
    scopes:[
        'user.read'
    ],
};

export const msalConfig = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_AUTHORITY_ID,
}

export const msalInstance = async () => {
    const msalApp = new PublicClientApplication({
        auth: msalConfig,
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        },
    });
    await msalApp.initialize();
    return msalApp
}