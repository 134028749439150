import { useEffect, useState } from "react";
import { Button, CustomInput, Label, Alert } from "reactstrap";
import dayjs from 'dayjs';
import FromToDateSelector from "components/Forms/FromToDateSelector";
import UserFeedbackTable from "./UserFeedbackTable";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Pagination } from "@mui/material";
import { useOrganization } from "hooks/organizationInstance";
import { defaultHeaders } from "components/auth/GetToken";
import axios from "components/axios/axios";
import ConversationDialog from "components/Dialogs/ConversationDialog";
import { toast } from "react-toastify";

const preStyles = {
    whiteSpace: 'pre-wrap', 
    wordWrap: 'break-word', 
    width: '100%', 
    maxHeight: '200px'
}

export default function UserFeedback() {
    const organizationInstance = useOrganization();

    const [feedbacks, setFeedbacks] = useState([]);
    const [fromDate, setFromDate] = useState(dayjs().subtract('6', 'day'))
    const [toDate, setToDate] = useState(dayjs())
    const [pipelines, setPipelines] = useState([]);

    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [selectedFeedbackDialogOpen, setSelectedFeedbackDialogOpen] = useState(false);

    const [conversationDialogOpen, setConversationDialogOpen] = useState(false);
    const [selectedConversationId, setSelectedConversationId] = useState('');

    const [selectedPipeline, setSelectedPipeline] = useState('All');
    const [selectedRating, setSelectedRating] = useState('All');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    async function fetchFeedbacks() {
        const headers = await defaultHeaders();
        const pipelineToFetch = selectedPipeline === 'All' || !selectedPipeline ? undefined : selectedPipeline;
        const ratingToFetch = selectedRating === 'All' || !selectedRating ? undefined : selectedRating;
        
        const itemsPerPage = 20;

        if (!organizationInstance.organization) {
            return;
        }

        axios.get(`/messageFeedback?itemsPerPage=${itemsPerPage}&currentPage=${page}&organisationId=${organizationInstance.organization.id}&startDate=${fromDate.toISOString().split('T')[0]}&endDate=${toDate.endOf('day').toISOString()}${pipelineToFetch ? '&pipelineId=' + pipelineToFetch : ''}${ratingToFetch ? '&rating=' + ratingToFetch : ''}`, headers)
            .then((res) => {
                setFeedbacks(res.data.content)
                setTotalPages(Math.ceil(res.data.totalElements / res.data.pageSize))
            })
            .catch((error) => {
                toast.error('Failed to fetch feedbacks')
            })
    }

    function fetchPipelinesForTenant() {
        if (organizationInstance.organization) {
            setPipelines([...organizationInstance.pipelines]);
        }
    }

    function handleFilter() {
        setPage(1);
        fetchFeedbacks();
    }

    function openFeedbackDetails(feedback) {
        setSelectedFeedback(feedback);
        setSelectedFeedbackDialogOpen(true);
    }

    useEffect(() => {
        fetchPipelinesForTenant();
        fetchFeedbacks();
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchFeedbacks();
        //eslint-disable-next-line
    }, [page])

    if (!organizationInstance.organization) {
        return (
            <div className='p-3 m-0'>
                <Alert color='warning'><span class="alert-icon"><i class="fa-solid fa-triangle-exclamation"></i></span>Please select an organization</Alert>
            </div>
        )
    }

    return (
        <div className="UserFeedback">
            <div className="row p-3 m-0">
                <div className="col-lg-10">
                    <UserFeedbackTable feedbacks={feedbacks} onClick={openFeedbackDetails}/>
                    <Pagination count={totalPages} page={page} onChange={(event, value) => { setPage(value) }} />
                </div>
                <div className="col-lg-2">
                    <FromToDateSelector fromDate={fromDate} toDate={toDate} onFromDateChange={setFromDate} onToDateChange={setToDate}/>
                    <Label for='pipeline'>Pipeline</Label>
                    <CustomInput
                        className='mb-3'
                        type='select'
                        id='pipeline'
                        onChange={(e) => setSelectedPipeline(e.target.value)}
                        value={selectedPipeline}
                    >
                        <option value='All'>All</option>
                        {pipelines.map(pipeline => <option key={pipeline.id} value={pipeline.id}>{pipeline.name}</option>)}
                    </CustomInput>
                    <Label for='rating'>Rating</Label>
                    <CustomInput
                        className="mb-3"
                        type='select'
                        id='rating'
                        onChange={(e) => setSelectedRating(e.target.value)}
                        value={selectedRating}
                    >
                        <option value='All'>All</option>
                        <option value='5'>Positive</option>
                        <option value='0'>Negative</option>
                    </CustomInput>
                    <Button onClick={handleFilter} color="primary" className='w-100'>Filter</Button>
                </div>
            </div>
            <Dialog maxWidth='sm' fullWidth open={selectedFeedbackDialogOpen} onClose={() => {setSelectedFeedbackDialogOpen(false)}}>
                <DialogTitle className="d-flex justify-content-between align-items-center">
                    User feedback details
                    <Button onClick={() => { setSelectedFeedbackDialogOpen(false); setSelectedFeedback(null) }} color="danger px-3" size='sm'>X</Button>
                </DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-between">
                        <p className="mb-0"><span className="font-weight-bold">Conversation: </span><pre className="d-inline">{selectedFeedback?.Message?.conversationId}</pre></p>
                        <Button onClick={() => {
                            setSelectedConversationId(selectedFeedback?.Message?.conversationId)
                            setConversationDialogOpen(true)
                        }} color="primary" size='sm'>View conversation</Button>
                    </div>
                    <p className="mb-0"><span className="font-weight-bold">Rating: </span>
                        {selectedFeedback?.rating === 5 && <i className="fa-regular fa-thumbs-up" style={{color: 'green'}}></i>} 
                        {selectedFeedback?.rating === 0 && <i class="fa-regular fa-thumbs-down" style={{color: 'red'}}></i>}
                    </p>
                    <p className="mb-0"><span className="font-weight-bold">Pipeline: </span>{selectedFeedback?.Message?.Pipeline?.name}</p>
                    <p><span className="font-weight-bold">Timestamp: </span>{selectedFeedback?.createdAt}</p>
                    <Divider className="mb-3"/>
                    <p className="mb-0"><span className="font-weight-bold">Feedback</span></p>
                    {
                        selectedFeedback?.message ?
                        <pre style={preStyles}>{selectedFeedback?.message}</pre>
                        :
                        <p className="font-italic">No feedback provided</p>
                    }
                    <Divider className="mb-3"/>
                    <p className="mb-0"><span className="font-weight-bold">Chat</span></p>
                    <small>Question</small>
                    <pre style={preStyles}>{selectedFeedback?.Message?.question}</pre>
                    <small>Answer</small>
                    <pre style={preStyles}>{selectedFeedback?.Message?.result}</pre>

                </DialogContent>
            </Dialog>
            <ConversationDialog open={conversationDialogOpen} conversationId={selectedConversationId} onClose={() => setConversationDialogOpen(false)} />
        </div>
    )
}