import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UniversalInput } from "../components/Forms/UniversalInput";
import { PageCard } from "../components/card/PageCard";
import { PaginationLink } from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { PageCardTabs } from '../components/card/PageCardTabs';
import { DeleteRole, UpdateRole, getRole } from "../hooks/roleInstance"
import { getPermissions } from "../hooks/permissionInstance"
import { useRole } from './Context/RoleContext';
import { useOrganization } from 'hooks/organizationInstance';
import { PermissionEnum } from "models/enums/permissionEnum";
import { usePermission } from 'hooks/permissionInstance';

export const EditRole = () => {
    const location = useLocation();
    const { role } = location.state || {}; // Destructuring to get 'role', with a fallback to {}
    const [modal, setModal] = useState(false);
    let navigate = useNavigate();

    const toggleModal = () => setModal(!modal);

    const [roleName, setRoleName] = useState(role.name);
    const [selectedPermissions, setSelectedPermissions] = useState(role ? role.permissions : []);

    const organisationId = useOrganization().organization.id;
    const permissionInstance = usePermission();


    const handleNameChange = (event) => {
        setRoleName(event.target.value);
    };

    const deleteRole = async () => {
        await DeleteRole(role.id);
        navigate('/role');

    };

    const saveRole = async () => {
        await UpdateRole(role.id, roleName, organisationId, selectedPermissions.map(permission => permission.id));
        navigate('/role');
    };

    const General = (
        <Form>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="name">Role Name</Label>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Full Name"
                            value={roleName} 
                            onChange={handleNameChange} />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Row>
                    <Col md={6} className="d-flex justify-content-start">
                        <Button color="success" onClick={saveRole}>Save</Button>
                    </Col>
                    {permissionInstance.permissions.includes(PermissionEnum.DELETE_ROLE) && ( 
                        <Col md={6} className="d-flex justify-content-end">
                            <Button color="danger" onClick={deleteRole}>Delete</Button>
                        </Col>
                    )}
                </Row>
            </FormGroup>
        </Form>
    );

    const RolesTab = () => {
        const [modal, setModal] = useState(false);
        const toggleModal = () => setModal(!modal);

        const [permissions, setPermissions] = useState([]);

        useEffect(() => {
            const fetchData = async () => {
                const storedPermissions = await getPermissions();
                if (storedPermissions) {
                    setPermissions(storedPermissions);
                } else {
                    console.log('Permissies niet gevonden in database.');
                }
            };
            fetchData(); 
        }, []);

        const availablePermissions = permissions.filter(p => !selectedPermissions.some(sp => sp.id === p.id));

        const addPermissionToSelected = (permission) => {
            setSelectedPermissions([...selectedPermissions, permission]);
        };

        const removePermissionFromSelected = (permission) => {
            setSelectedPermissions(selectedPermissions.filter(p => p !== permission));
        };

        return (
            <Form>
                <FormGroup>
                    <div className="d-flex justify-content-between align-items-center">
                        <Label className="mb-0">Current Permissions</Label>
                        <Button className='mb-3' color="primary" onClick={toggleModal}>
                            Add
                        </Button>
                    </div>
                    <ListGroup className="flex-grow-1">
                        {selectedPermissions.map((permission, index) => (
                            <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                                {permission.name}
                                <Button color="danger" size="sm" onClick={() => removePermissionFromSelected(permission)}>
                                    <i className="fas fa-trash"></i>
                                </Button>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </FormGroup>

                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Available Permissions</ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            {availablePermissions.map((permission, index) => (
                                <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                                    {permission.name}
                                    <Button color="success" size="sm" onClick={() => {
                                        addPermissionToSelected(permission);
                                    }}>
                                        <i className="fas fa-plus"></i>

                                    </Button>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </Form>
        );
    };


    const tabItems = [
        { tabId: '1', title: 'General', content: General },
        { tabId: '2', title: 'Permissions', content: RolesTab() }
    ];
    return (
        <PageCardTabs cardName="Edit Role" tabItems={tabItems} />


    );
}

export default EditRole;
