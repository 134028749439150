import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useOrganization } from "hooks/organizationInstance"
import { useNavigate } from "react-router-dom"
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Navbar,
    Nav,
    Container,
} from "reactstrap";
import { usePermission } from "hooks/permissionInstance";
import { PermissionEnum } from "models/enums/permissionEnum";

function NavigationBar({ theme }) {
    const navigate = useNavigate();
    const organizationInstance = useOrganization();
    const permissionInstance = usePermission();

    const [organizations, setOrganizations] = useState([]);

    useEffect(() => {
        setOrganizations(organizationInstance.organizations);
    }, [organizationInstance]);
    
    return (
        <>
            <Navbar
                className={classnames(
                    "navbar-top navbar-expand border-bottom",
                    { "navbar-dark bg-info": theme === "dark" },
                    { "navbar-light bg-secondary": theme === "light" },
                )}
            >
                <Container fluid>
                    <Collapse navbar isOpen={true}>
                        <Nav
                            className="align-items-center ml-auto md-0 mr-0"
                            navbar
                        >
                            <UncontrolledDropdown nav>
                                <DropdownToggle
                                    className="nav-link border rounded py-2 px-4"
                                    color=""
                                    tag="a"
                                >   <i className="ni ni-building" style={{ marginRight: '1rem' }}></i>
                                    <span/>
                                    {!organizationInstance.organization ?(
                                        <span className="font-weight-bold"> <i className="ni ni-fat-add"/> add Organization</span>
                                    ) : (
                                            <span className="mb-0 text-xl font-weight-bold">{organizationInstance.organization?.name}</span>
                                    )}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {(organizations.length >= 1) ? organizations.map((organization) => (
                                        <DropdownItem
                                            key={organization.name}
                                            onClick={(_) => organizationInstance.setOrganization(organization)}
                                        >
                                            <i className="ni ni-building"/>
                                            <span>{organization.name}</span>
                                        </DropdownItem>
                                    )) : <p className="mx-4">No organizations</p>}
                                    {permissionInstance.permissions.includes(PermissionEnum.CREATE_ORGANISATION) && ( //TODO check permission
                                        <DropdownItem key={"add org"}
                                            onClick={() => {navigate("/add-Organization")}}
                                        >
                                            <i className="ni ni-fat-add" />
                                            Add organization
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

NavigationBar.defaultProps = {
    toggleSidenav: () => {},
    sidenavOpen: false,
    theme: "dark",
};
NavigationBar.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf(["dark", "light"]),
};

export default NavigationBar;
